/* eslint-disable no-useless-escape */

export function ValidateBranchPhone(phoneInput) {

  const errors = {};

  if (!/^\d{1,5}$/.test(phoneInput.areaCode)) {
    errors.areaCode = "El codigo de area solo debe contener numeros (longitud del codigo maxima: 5).";
  } else if (!/^\d+$/.test(phoneInput.number)) {
    errors.number = "El telefono solo debe contener numeros.";
  }

  return errors;
}