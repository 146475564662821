import React, { useState, useEffect } from "react";
import "./EditBranchContact.scss"
import axios from "axios";
import { ValidateBranchPhone } from "../../../validations/ValidateBranchPhone";
import { ValidateBranchEmail } from "../../../validations/ValidateBranchEmail";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function EditBranchContact({ branchId, closeModal }) {

    const [arrayInput, setArrayInput] = useState({
        branchPhones: [],
        branchEmails: []
    });

    const [phoneInput, setPhoneInput] = useState({
        areaCode: "",
        number: "",
        typeId: 1,
    });

    const [phoneErrors, setPhoneErrors] = useState({});


    const [emailInput, setEmailInput] = useState({
        email: "",
        branchId: "",
    });

    const [emailErrors, setEmailErrors] = useState({});

    const getBranch = async () => {
        try {
            const response = await axios.get(`https://www.viverosantaisabel.com/api/branch/${branchId}`);
            const data = response.data.data;
            setArrayInput({
                branchPhones: data.branch_phone,
                branchEmails: data.branch_email,
            });
            console.log(arrayInput)
        } catch (err) {
        }
    };

    useEffect(() => {
        getBranch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const postBranchPhone = async (e) => {
        e.preventDefault();
        const id = parseInt(branchId)
        const areaCode = parseInt(phoneInput.areaCode)
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            await axios.post("https://www.viverosantaisabel.com/api/branch/phone", {

                areaCode: areaCode,
                number: phoneInput.number,
                typeId: parseInt(phoneInput.typeId),
                branchId: id,

            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                });
        } catch (err) {
        }

        setPhoneInput({
            areaCode: "",
            number: "",
            typeId: 1,
            branchId: "",
        });
        getBranch();
    };

    const handleChangePhone = (e) => {
        setPhoneInput({
            ...phoneInput,
            [e.target.name]: e.target.value,
        });
        console.log(phoneInput)
        setPhoneErrors(ValidateBranchPhone({ ...phoneInput, [e.target.name]: e.target.value }));
    };

    const deletePhone = async (e) => {
        e.preventDefault();
        const id = parseInt(e.target.value)
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            await axios.post("https://www.viverosantaisabel.com/api/branch/phone?_method=DELETE", {

                phoneId: id

            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                });
        } catch (err) {
        }
        getBranch();
    };

    const postBranchEmail = async (e) => {
        e.preventDefault();
        const id = parseInt(branchId)
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            await axios.post("https://www.viverosantaisabel.com/api/branch/email", {

                email: emailInput.email,
                branchId: id,

            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                });
        } catch (err) {
        }

        setEmailInput({
            email: "",
            branchId: "",
        });
        getBranch();
    };

    const handleChangeEmail = (e) => {
        setEmailInput({
            ...emailInput,
            [e.target.name]: e.target.value,
        });
        setEmailErrors(ValidateBranchEmail({ ...emailInput, [e.target.name]: e.target.value }));
    };

    const deleteEmail = async (e) => {
        e.preventDefault();
        const id = parseInt(e.target.value)
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            await axios.post("https://www.viverosantaisabel.com/api/branch/email?_method=DELETE", {

                emailId: id

            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                });
        } catch (err) {
        }
        getBranch();
    };




    function goBack() {
        closeModal()
    }

    return (
        <div className="edit-branch-contact-container">
            <button className="edit-branch-contact-goback" onClick={goBack}>Volver</button>

            <div className="edit-branch-contact-add-items-container">
                <div className="edit-branch-contact-add-item-container">
                    <label className="edit-branch-contact-add-item-label">Agregar un telefono</label>

                    <form onSubmit={(e) => postBranchPhone(e)} className="edit-branch-contact-add-item-input-container">

                        <select
                            className="edit-branch-contact-add-item-select"
                            onChange={(e) => handleChangePhone(e)}
                            name="typeId"
                            value={phoneInput.typeId}>
                            <option value={1}>Fijo</option>
                            <option value={2}>WhatsApp<FontAwesomeIcon className="edit-branch-icon" icon={faWhatsapp}></FontAwesomeIcon></option>
                        </select>

                        <input
                            placeholder="Codigo"
                            name="areaCode"
                            value={phoneInput.areaCode}
                            autoComplete="off"
                            onChange={(e) => handleChangePhone(e)}
                            className="edit-branch-contact-add-item-input-short"
                        >

                        </input>

                        {phoneErrors.areaCode && (
                            <div className="edit-branch-contact-error">{phoneErrors.areaCode}</div>
                        )}

                        {phoneErrors.number && (
                            <div className="edit-branch-contact-error">{phoneErrors.number}</div>
                        )}

                        <input
                            placeholder="Numero"
                            value={phoneInput.number}
                            name="number"
                            autoComplete="off"
                            onChange={(e) => handleChangePhone(e)}
                            className="edit-branch-contact-add-item-input-large"
                        >

                        </input>



                        <button type="submit" className={
                            phoneInput.areaCode === "" ||
                                phoneInput.number === "" ||
                                phoneErrors.areaCode ||
                                phoneErrors.number ? "edit-branch-contact-add-item-btn-disabled" : "edit-branch-contact-add-item-btn"}>Agregar</button>
                    </form>

                </div>

                <div className="edit-branch-contact-add-item-container">
                    <label className="edit-branch-contact-add-item-label">Agregar un correo electronico</label>

                    <form onSubmit={(e) => postBranchEmail(e)} className="edit-branch-contact-add-item-input-container">
                        <input
                            className="edit-branch-contact-add-item-input"
                            placeholder="Correo electronico"
                            name="email"
                            autoComplete="off"
                            onChange={(e) => handleChangeEmail(e)}
                            value={emailInput.email}
                        >
                        </input>

                        {emailErrors.email && (
                            <div className="edit-branch-contact-error">{emailErrors.email}</div>
                        )}

                        <button type="submit" className={
                            emailInput.email === "" ||
                                emailErrors.email ? "edit-branch-contact-add-item-btn-disabled" : "edit-branch-contact-add-item-btn"}>Agregar
                        </button>
                    </form>

                </div>
            </div>


            <div className="edit-branch-contact-items-general-container">
                <div className="edit-branch-contact-items-container">

                    <div className="edit-branch-contact-items-title">Telefonos:</div>
                    {
                        arrayInput.branchPhones && arrayInput.branchPhones.map((e, index) => {
                            return (
                                <div className="edit-branch-contact-item-container" key={index}>


                                    <div className="edit-branch-contact-item">
                                        {
                                            e.phone_type.type_id === 1 && (
                                                <FontAwesomeIcon icon={faPhone} className="edit-branch-contact-icon" />
                                            )
                                        }
                                        {
                                            e.phone_type.type_id === 2 && (
                                                <FontAwesomeIcon icon={faWhatsapp} className="edit-branch-contact-icon" />
                                            )
                                        } +54 {e.area_code} {e.number}</div>
                                    <button onClick={(e) => deletePhone(e)} className="edit-branch-contact-item-btn" value={e.phone_id}>Borrar</button>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="edit-branch-contact-items-container">

                    <div className="edit-branch-contact-items-title">Correos electronicos:</div>

                    {
                        arrayInput.branchEmails && arrayInput.branchEmails.map((e, index) => {
                            return (
                                <div className="edit-branch-contact-item-container" key={index}>
                                    <div className="edit-branch-contact-item">{e.email}</div>
                                    <button onClick={(e) => deleteEmail(e)} value={e.email_id} className="edit-branch-contact-item-btn">Borrar</button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default EditBranchContact;