import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PostEdit.scss"
import { ValidatePost } from "../../../validations/ValidatePost";
import spinner from "../../../resources/images/gallery/spinner.gif"

function PostEdit({ postId, closeModal }) {

    // Spinner state
    const [isLoading, setIsLoading] = useState(false);

    // function close modal
    function goBack() {
        closeModal()
    }

    // useState for input content
    const [input, setInput] = useState({
        title: "",
        text: "",
        videoUrl: "",
        buttonError: false
    });

    // useState for input errors display
    const [errors, setErrors] = useState({});

    // function for input value change and validation
    const handleChange = (e) => {
        setInput({
            ...input,
            buttonError: false,
            [e.target.name]: e.target.value,
        });
        setErrors(ValidatePost({ ...input, [e.target.name]: e.target.value }));
    };

    // useState for file name display
    const [fileName, setFileName] = useState('');

    const [fileError, setFileError] = useState(false);

    // function for file name display
    const handleFileInputChange = (event) => {
        setFileError(false)
        const file = event.target.files[0];
        // File size validation: check if the file size is greater than 5MB (5000000 bytes)
        if (file.size > 1000000) {
            // Display an error message or take appropriate action
            setFileError(true)
            event.target.value = "";
            return;
        }
        setFileName(file);
    };

    // useState for video checkbox
    const [videoCheck, setVideoCheck] = useState(false);

    // function for video checkbox
    const handleVideoCheckboxChange = () => {
        setVideoCheck(!videoCheck);
        setInput({
            ...input,
            videoUrl: "",
        })
    };

    // useState for gallery checkbox
    const [galleryCheck, setGalleryCheck] = useState(false);

    // function for gallery checkbox
    const handleGalleryCheckboxChange = () => {
        setGalleryCheck(!galleryCheck);
        setImages([])
    };

    // useStates for images gallery
    const [images, setImages] = useState([]);
    const [maxImages] = useState(5);
    const [galleryError, setGalleryError] = useState(false);


    // function for images gallery change
    const handleImagesInputChange = (event, index) => {
        setGalleryError(false)
        const image = event.target.files[0];
        if (image.size > 2000000) {
            // Display an error message or take appropriate action
            setGalleryError(true)
            event.target.value = "";
            return;
        } else {
            const newImages = [...images];
            newImages[index] = image;
            setImages(newImages);
        }

    };

    // function for images gallery add
    const addImageInput = (e) => {
        e.preventDefault()
        if (images.length < maxImages) {
            const newImages = [...images, null];
            setImages(newImages);
        }
    };

    // function for images gallery remove
    const removeImageInput = (e, index) => {
        e.preventDefault()
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    // useState for gallery checkbox
    const [featuredCheck, setFeaturedCheck] = useState(false);

    // function for featured checkbox
    const handleFeaturedCheckboxChange = () => {
        setFeaturedCheck(!featuredCheck);
    };

    // function to get post and set inputs
    const getPost = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://www.viverosantaisabel.com/api/post/${postId}`);
            const data = response.data.data;

            setInput({
                title: data.title,
                text: data.text,
            });
            if (data.featured) handleFeaturedCheckboxChange()
        } catch (err) {
        }
        setIsLoading(false);
    };

    // useEffect to get post and set inputs
    useEffect(() => {
        getPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        const formData = new FormData();

        console.log(input)
        console.log(featuredCheck)

        if (postId !== "") {
            console.log(postId)
            formData.append("postId", postId);
        } else {
            goBack();
        }

        formData.append("title", input.title);
        formData.append("text", input.text);
        if (input.thumbnail !== "") {
            console.log(fileName)
            formData.append("thumbnail", fileName);
        }        
        console.log(featuredCheck)
        formData.append("featured", featuredCheck);

        try {

            await axios.post('https://www.viverosantaisabel.com/api/post?_method=PUT',
                formData,

                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }

            );
        } catch (error) {
            console.log(error)
            setInput({
                ...input,
                buttonError: true
            })
        }

        if (videoCheck || galleryCheck) {

            if (videoCheck) {
                console.log(input.videoUrl)
                const formDataVideo = new FormData();
                if (postId !== "") formDataVideo.append("postId", postId);
                if (input.videoUrl !== "") formDataVideo.append("videoUrl", input.videoUrl);

                try {

                    await axios.post('https://www.viverosantaisabel.com/api/post/galery/multimedia',
                        formDataVideo,

                        {
                            headers: { Authorization: `Bearer ${userToken}` }
                        }

                    );
                    // if (response.data.status === 201) {
                    //     goBack()
                    // }
                } catch (error) {
                    console.log(error)
                    setInput({
                        ...input,
                        buttonError: true
                    })
                }
            }

            if (galleryCheck) {
                const formDataGallery = new FormData();
                if (postId !== "") formDataGallery.append("postId", postId);
                images.forEach((image) => {
                    formDataGallery.append(`images`, image);
                });

                try {

                    await axios.post('https://www.viverosantaisabel.com/api/post/galery/multimedia',
                        formDataGallery,

                        {
                            headers: { Authorization: `Bearer ${userToken}` }
                        }

                    );
                    // if (response.data.status === 201) {
                    //     goBack()
                    // }
                } catch (error) {
                    console.log(error)
                    setInput({
                        ...input,
                        buttonError: true
                    })
                }
            }
        }
        setIsLoading(false);
        goBack()
    };

    return (

        <div className="post-edit-container">

            <button className="post-edit-go-back" onClick={goBack}>Volver</button>

            <div className="edit-post-title">Editar post</div>

            {isLoading ? (
                <div className="spinner-container">
                    <img alt="Cargando..." src={spinner} className="spinner"></img>
                </div>
            ) : (
                <form className="edit-post-form" onSubmit={(e) => handleSubmit(e)}>

                    <div className="edit-post-input-container">
                        <label className="edit-post-input-label">Editar titulo de la noticia</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="edit-post-input"
                            placeholder="Titulo de la noticia"
                            name="title"
                            autoComplete="off"
                            value={input.title}
                            onChange={(e) => handleChange(e)}
                        ></input>

                        {errors.title && (
                            <div className="post-form-error">{errors.title}</div>
                        )}
                    </div>

                    <div className="edit-post-input-container">
                        <label className="edit-post-input-label">Editar texto de la noticia</label>
                        <label className="edit-post-input-label-small">Para generar un salto de linea presiona Mayús + Enter</label>
                        <textarea
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                }
                            }}
                            className="edit-post-input-textarea"
                            type="text"
                            placeholder="Redacta la noticia"
                            name="text"
                            autoComplete="off"
                            value={input.text}
                            onChange={(e) => handleChange(e)}
                        ></textarea>

                        {errors.text && (
                            <div className="post-form-error">{errors.text}</div>
                        )}
                    </div>

                    <label className="edit-post-input-label">Editar portada de la noticia</label>

                    <div className="edit-post-input-file-container">
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="edit-post-input-file"
                            type="file"
                            onChange={handleFileInputChange}
                        ></input>
                        <label className="edit-post-file-label">{fileName ? fileName.name : "Arrastra la imagen aca o hace click para seleccionarla."}</label>
                        {fileError && (
                            <div className="post-form-error">Tamaño maximo de imagen: 1MB</div>
                        )}
                    </div>

                    <div className="edit-post-checkbox-video-container">

                        <div className="edit-post-checkbox-video-text-container">

                            <div className="edit-post-checkbox-video-labels-container">
                                <label className="edit-post-checkbox-video-label">¿Incluir un video?</label>
                                <label className="edit-post-checkbox-video-label-small">Esta acción reemplazara el video actual.</label>
                            </div>

                            <input
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                                className="edit-post-checkbox-video-checkbox"
                                onChange={handleVideoCheckboxChange}
                                type="checkbox"
                            >
                            </input>
                        </div>

                        {
                            videoCheck && (
                                <div className="edit-post-checkbox-video-input-container">
                                    <label className="edit-post-checkbox-video-input-label">Enlace del video</label>
                                    <input
                                        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                                        className="edit-post-checkbox-video-input"
                                        placeholder="Enlace a el video"
                                        name="videoUrl"
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                    ></input>

                                    {errors.videoUrl && (
                                        <div className="post-form-error">{errors.videoUrl}</div>
                                    )}
                                </div>

                            )
                        }
                    </div>

                    <div className="edit-post-checkbox-gallery-container">

                        <div className="edit-post-checkbox-gallery-text-container">
                            <div className="edit-post-checkbox-gallery-labels-container">
                                <label className="edit-post-checkbox-gallery-label">¿Incluir imagenes?</label>
                                <label className="edit-post-checkbox-gallery-label-small">Esta acción reemplazara la galeria de fotos actual.</label>
                            </div>
                            <input
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                                className="edit-post-checkbox-gallery-checkbox"
                                onChange={handleGalleryCheckboxChange}
                                type="checkbox"
                            >
                            </input>
                        </div>

                        {
                            galleryCheck && (
                                <div className="edit-post-checkbox-gallery-inputs-container">

                                    {images.length < maxImages && (
                                        <button className="edit-post-checkbox-gallery-add" onClick={(e) => addImageInput(e)}>Añadir imagen</button>
                                    )}

                                    {images.map((image, index) => (
                                        <div className="edit-post-checkbox-gallery-single-input-container" key={index}>
                                            <input
                                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                                className="edit-post-checkbox-gallery-input"
                                                type="file"
                                                onChange={(event) => handleImagesInputChange(event, index)}
                                            />

                                            <button className="edit-post-checkbox-gallery-remove" onClick={(e) => removeImageInput(e, index)}>Borrar</button>


                                        </div>
                                    ))}
                                    {galleryError && (
                                        <div className="post-form-error">La imagen que seleccionaste excede el tamaño maximo: 1MB</div>
                                    )}
                                </div>

                            )
                        }
                    </div>


                    <div className="edit-post-checkbox-featured-container">
                        <label className="edit-post-checkbox-featured-label">¿Destacar la noticia?</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="edit-post-checkbox-featured-checkbox"
                            onChange={handleFeaturedCheckboxChange}
                            type="checkbox"
                            checked={featuredCheck}
                        >
                        </input>
                    </div>

                    <button
                        className={
                            input.title === "" ||
                                input.text === "" ||
                                errors.title ||
                                errors.text ||
                                fileError ||
                                (videoCheck && errors.videoUrl) ||
                                galleryError ? "edit-post-submit-btn-disabled" : "edit-post-submit-btn"
                        }

                        type="submit">{input.buttonError ? "Error en los datos" : "Editar post"}
                    </button>

                </form>
            )}



        </div>
    )
}

export default PostEdit;