import React, { useEffect, useState } from "react";
import "./ShowProducts.scss";
import axios from "axios";
import spinner from "../../../resources/images/gallery/spinner.gif"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function ShowProducts() {

    // Spinner state
    const [isLoading, setIsLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)

    const [categories, setCategories] = useState([]);
    const [openCategory, setOpenCategory] = useState(null);

    const handlePrevPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageButtons = () => {
        const maxButtons = 5;
        const halfButtons = Math.floor(maxButtons / 2);
        const startPage = Math.max(currentPage - halfButtons, 1);
        const endPage = Math.min(startPage + maxButtons - 1, totalPages);

        const buttons = [];
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    className={`products-menu-pagination-btn-number ${i === currentPage ? "active" : ""}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }

        return buttons;
    };


    useEffect(() => {
        setIsLoading(true);
        const getCategories = async () => {
            try {
                const response = await axios.get("https://www.viverosantaisabel.com/api/product/category/");
                const data = response.data.data;
                setCategories(data);
            } catch (err) {
            }
        };
        getCategories();
        setIsLoading(false);
    }, [])

    useEffect(() => {
        const getProductsPagination = async () => {
            try {
                const response = await axios.get(`https://www.viverosantaisabel.com/api/product/category/${openCategory}?page=${currentPage}`);
                const data = response.data.data;
                setProducts(data);
                setTotalPages(response.data.totalPages);

            } catch (err) {
                // handle error
            }
        }
        getProductsPagination(openCategory)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])


    const getProducts = async (categoryId) => {
        try {
            // If the clicked category is already open, close it
            if (openCategory === categoryId) {
                setCurrentPage(1)
                setOpenCategory(null);
            } else {
                // If a different category is clicked, fetch products and open it
                setCurrentPage(1)
                const response = await axios.get(`https://www.viverosantaisabel.com/api/product/category/${categoryId}?page=${currentPage}`);
                const data = response.data.data;
                setProducts(data);
                setTotalPages(response.data.totalPages);
                setOpenCategory(categoryId);
            }
        } catch (err) {
            // handle error
        }
    }

    const getProductsDelete = async (categoryId) => {
        try {
            const response = await axios.get(`https://www.viverosantaisabel.com/api/product/category/${openCategory}?page=${currentPage}`);
            const data = response.data.data;
            setProducts(data);
            setTotalPages(response.data.totalPages);

        } catch (err) {
            // handle error
            setOpenCategory(null)
        }
    }

    const deleteProduct = async (productId, categoryId) => {

        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {

            await axios.post('https://www.viverosantaisabel.com/api/product?_method=DELETE',

                {
                    productId: productId
                },

                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }

            );
            getProductsDelete(categoryId);
        } catch (error) {

        }
    }



    return (

        <div className="show-products-container">

            {isLoading ? (
                <div className="spinner-container">
                    <img alt="Cargando..." src={spinner} className="spinner"></img>
                </div>
            ) : (

                <div className="show-products-map-container">
                    <div className="show-products-title">Categorias</div>

                    {
                        categories && categories.map((e, key) => {
                            return (
                                <div className="show-products-single-container" key={key}>
                                    <div key={e.category_id} className="show-products-category-name-container">
                                        <div className="show-products-category-name">{e.name}</div>
                                        <button
                                            className="show-products-single-expand-btn"
                                            onClick={() => getProducts(e.category_id)}
                                        >
                                            Productos de la categoria
                                            <FontAwesomeIcon
                                                className={
                                                    openCategory === e.category_id
                                                        ? "show-products-icon-expand-rotated"
                                                        : "show-products-icon-expand"
                                                }
                                                icon={faChevronDown}
                                            ></FontAwesomeIcon>
                                        </button>
                                    </div>
                                    {openCategory === e.category_id && (
                                        <div className="show-products-products-dropdown">
                                            {products.map((product) => (
                                                <div key={product.product_id} className="product-item">
                                                    <div className="product-name">{product.description}</div>
                                                    <div className="product-delete" onClick={() => deleteProduct(product.product_id, e.category_id)}>Borrar</div>
                                                </div>
                                            ))}
                                            <div className="products-menu-pagination-container">
                                                <button
                                                    className="products-menu-pagination-btn"
                                                    disabled={currentPage === 1}
                                                    onClick={handlePrevPage}
                                                >
                                                    Anterior
                                                </button>

                                                <div className="products-menu-pagination-btn-number-container">
                                                    {renderPageButtons()}
                                                </div>

                                                <button
                                                    className="products-menu-pagination-btn"
                                                    disabled={currentPage === totalPages}
                                                    onClick={handleNextPage}
                                                >
                                                    Siguiente
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )

                        })
                    }
                </div>
            )}
        </div>
    )
}

export default ShowProducts;