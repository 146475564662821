/* eslint-disable no-useless-escape */

export function ValidatePost(input) {

  const errors = {};

  if (!/^.{5,60}$/.test(input.title)) {
    errors.title = "El titulo debe tener entre 5 y 60 caracteres.";
  } else
    if (!/^[\s\S]{20,5000}$/.test(input.text)) {
      errors.text = "El texto debe contener un mínimo de 20 letras (máximo: 5000 letras)";
    } else
      if (!/^(?:(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]{11}))(?:\S+)?$/.test(input.videoUrl)) {
        errors.videoUrl = "Ingresa una URL de YouTube valida";
      }

  return errors;
}