import React from "react";
import "./About.scss"
import about from '../../../resources/images/n2.webp'


function About() {

    return (

        <div className="about-container">

            {/* <div className="about-top-container">
                <div className="about-sub-title">¿Quienes somos?</div>
                <div className="about-title">Conoce uno de los mas grandes viveros productores de la Argentina.</div>
            </div> */}

            <div className="about-bottom-container">
                <div className="about-bottom-text-container">

                    <div className="about-bottom-title">Cultivando tu jardín desde <span className="about-bottom-title-font">1936</span></div>

                    <div className="about-bottom-paraf">Llevamos más de 87 años como un vivero que da vida a todo tipo de ambientes con calidad y variedad, desde árboles y palmeras, hasta arbustos y plantas de hogar.</div>

                    <a className="about-bottom-btn" href="/contacto">Visitanos</a>

                </div>

                <img className="about-bottom-img" src={about} alt="Cargando..."></img>
            </div>

        </div>
    )
}

export default About;