import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PostMenu.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PostEdit from "../PostEdit/PostEdit";
import spinner from "../../../resources/images/gallery/spinner.gif"
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";

function PostMenu() {
    // Spinner state
    const [isLoading, setIsLoading] = useState(false);

    // Get posts list
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        const getPosts = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `https://www.viverosantaisabel.com/api/post?page=${currentPage}`
                );
                const data = response.data.data;
                setPosts(data);
                setTotalPages(response.data.totalPages);
            } catch (err) {
            }
            setIsLoading(false);
        };

        getPosts();
    }, [currentPage]);

    const handlePrevPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageButtons = () => {
        const maxButtons = 5;
        const halfButtons = Math.floor(maxButtons / 2);
        const startPage = Math.max(currentPage - halfButtons, 1);
        const endPage = Math.min(startPage + maxButtons - 1, totalPages);

        const buttons = [];
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    className={`posts-menu-pagination-btn-number ${i === currentPage ? "active" : ""}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }

        return buttons;
    };

    const GetPostsDefault = async () => {
        setIsLoading(true);
        console.log("data")
        try {
            const response = await axios.get("https://www.viverosantaisabel.com/api/post?page=1");
            const data = response.data.data;
            setPosts(data)
        } catch (err) {
            setPosts([])
        }
        setIsLoading(false);
    };

    // Edit post modal management
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState("");

    function OpenModal(e, postId) {
        setModalData(postId)
        setOpenModal(true)
    }

    function CloseModal() {
        setOpenModal(false)
        GetPostsDefault();
    }

    const DeletePost = async (postId) => {
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        setIsLoading(true);
        try {
            await axios.post("https://www.viverosantaisabel.com/api/post?_method=DELETE", {
                postId: postId
            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }
            );
            GetPostsDefault();

        } catch (err) {
        }
        setIsLoading(false);
    }

    return (

        <div className="posts-menu-container">
            {openModal && (
                <div className="posts-menu-modal-container">
                    <PostEdit closeModal={CloseModal} postId={modalData}></PostEdit>
                </div>
            )}

            {isLoading ? (
                <div className="spinner-container">
                    <img alt="Cargando..." src={spinner} className="spinner"></img>
                </div>
            ) : (
                <div className="posts-menu-once-loaded-container">
                    {posts &&
                        posts.map((post) => {
                            return (
                                <div key={post.post_id} className="posts-menu-single-container">
                                    <div className="post-menu-btns-container">
                                        <div
                                            className="post-menu-btn-edit"
                                            onClick={(e) => OpenModal(e, post.post_id)}
                                        >
                                            <FontAwesomeIcon
                                                className="post-menu-btn-icon"
                                                icon={faEdit}
                                                onClick={(e) => OpenModal(e, post.post_id)}
                                            ></FontAwesomeIcon>
                                        </div>

                                        <div
                                            className="post-menu-btn-delete"
                                            onClick={() => DeletePost(post.post_id)}
                                        >
                                            <FontAwesomeIcon
                                                className="post-menu-btn-icon"
                                                icon={faTrashCan}
                                            ></FontAwesomeIcon>
                                        </div>
                                    </div>
                                    <img
                                        className="posts-menu-single-img"
                                        key={post.post_id}
                                        src={
                                            post.thumbnail
                                                ? URL.createObjectURL(
                                                    new Blob([new Uint8Array(post.thumbnail.data)], {
                                                        type: "image/png",
                                                    })
                                                )
                                                : ""
                                        }
                                        alt={post.title}
                                    ></img>
                                    <div className="posts-menu-signle-title">{post.title}</div>
                                    <div className="posts-menu-single-subtitle">
                                        {post.text.slice(0, 70)}...
                                    </div>
                                </div>
                            );
                        })}
                    <div className="posts-menu-pagination-container">
                        <button
                            className="posts-menu-pagination-btn"
                            disabled={currentPage === 1}
                            onClick={handlePrevPage}
                        >
                            Anterior
                        </button>
                        <div className="posts-menu-pagination-btn-number-container">
                            {renderPageButtons()}
                        </div>
                        <button
                            className="posts-menu-pagination-btn"
                            disabled={currentPage === totalPages}
                            onClick={handleNextPage}
                        >
                            Siguiente
                        </button>
                    </div>
                </div>
            )}
        </div>

    )
}

export default PostMenu;