import React from "react";
import { useState } from "react";
import Footer from "../Footer/Footer";
import Nav from "../Nav/Nav";
import "./Gallery.scss"
import LazyLoad from 'react-lazy-load';

import ft1 from './../../resources/images/gallery/ft1.webp'
import ft2 from './../../resources/images/gallery/ft2.webp'
import ft3 from './../../resources/images/gallery/ft3.webp'
import ft4 from './../../resources/images/gallery/ft4.webp'
import ft5 from './../../resources/images/gallery/ft5.webp'
import ft6 from './../../resources/images/gallery/ft6.webp'
import ft7 from './../../resources/images/gallery/ft7.webp'
import ft8 from './../../resources/images/gallery/ft8.webp'
import ft9 from './../../resources/images/gallery/ft9.webp'
import ft10 from './../../resources/images/gallery/ft10.webp'
import ft11 from './../../resources/images/gallery/ft11.webp'
import ft12 from './../../resources/images/gallery/ft12.webp'
import ft13 from './../../resources/images/gallery/ft13.webp'
import ft14 from './../../resources/images/gallery/ft14.webp'
import ft15 from './../../resources/images/gallery/ft15.webp'
import ft16 from './../../resources/images/gallery/ft16.webp'
import ft17 from './../../resources/images/gallery/ft17.webp'
import ft18 from './../../resources/images/gallery/ft18.webp'
import ft19 from './../../resources/images/gallery/ft19.webp'
import ft20 from './../../resources/images/gallery/ft20.webp'
import ft21 from './../../resources/images/gallery/ft21.webp'
import ft22 from './../../resources/images/gallery/ft22.webp'
import ft23 from './../../resources/images/gallery/ft23.webp'
import ft24 from './../../resources/images/gallery/ft24.webp'
import ft25 from './../../resources/images/gallery/ft25.webp'
import ft26 from './../../resources/images/gallery/ft26.webp'
import ft27 from './../../resources/images/gallery/ft27.webp'
import ft28 from './../../resources/images/gallery/ft28.webp'
import ft29 from './../../resources/images/gallery/ft29.webp'
import ft30 from './../../resources/images/gallery/ft30.webp'
import ft31 from './../../resources/images/gallery/ft31.webp'
import ft32 from './../../resources/images/gallery/ft32.webp'
import ft33 from './../../resources/images/gallery/ft33.webp'
import ft34 from './../../resources/images/gallery/ft34.webp'
import ft35 from './../../resources/images/gallery/ft35.webp'
import ft36 from './../../resources/images/gallery/ft36.webp'
import ft37 from './../../resources/images/gallery/ft37.webp'
import ft38 from './../../resources/images/gallery/ft38.webp'
import ft39 from './../../resources/images/gallery/ft39.webp'
import ft40 from './../../resources/images/gallery/ft40.webp'

function Gallery() {

    let data = [
        {
            id: 1,
            imgSrc: ft1,
            imgSrcSmall: ft1
        },

        {
            id: 2,
            imgSrc: ft2,
            imgSrcSmall: ft2
        },
        {
            id: 3,
            imgSrc: ft3,
            imgSrcSmall: ft3
        },
        {
            id: 4,
            imgSrc: ft4,
            imgSrcSmall: ft4
        },
        {
            id: 5,
            imgSrc: ft5,
            imgSrcSmall: ft5
        },
        {
            id: 6,
            imgSrc: ft6,
            imgSrcSmall: ft6
        },
        {
            id: 7,
            imgSrc: ft7,
            imgSrcSmall: ft7
        },
        {
            id: 8,
            imgSrc: ft8,
            imgSrcSmall: ft8
        },
        {
            id: 9,
            imgSrc: ft9,
            imgSrcSmall: ft9
        },
        {
            id: 10,
            imgSrc: ft10,
            imgSrcSmall: ft10
        },
        {
            id: 11,
            imgSrc: ft11,
            imgSrcSmall: ft11
        },
        {
            id: 12,
            imgSrc: ft12,
            imgSrcSmall: ft12
        },
        {
            id: 13,
            imgSrc: ft13,
            imgSrcSmall: ft13
        },
        {
            id: 14,
            imgSrc: ft14,
            imgSrcSmall: ft14
        },
        {
            id: 15,
            imgSrc: ft15,
            imgSrcSmall: ft15
        },
        {
            id: 16,
            imgSrc: ft16,
            imgSrcSmall: ft16
        },
        {
            id: 17,
            imgSrc: ft17,
            imgSrcSmall: ft17
        },
        {
            id: 18,
            imgSrc: ft18,
            imgSrcSmall: ft18
        },
        {
            id: 19,
            imgSrc: ft19,
            imgSrcSmall: ft19
        },
        {
            id: 20,
            imgSrc: ft20,
            imgSrcSmall: ft20
        },
        {
            id: 21,
            imgSrc: ft21,
            imgSrcSmall: ft21
        },
        {
            id: 22,
            imgSrc: ft22,
            imgSrcSmall: ft22
        },
        {
            id: 23,
            imgSrc: ft23,
            imgSrcSmall: ft23
        },
        {
            id: 24,
            imgSrc: ft24,
            imgSrcSmall: ft24
        },
        {
            id: 25,
            imgSrc: ft25,
            imgSrcSmall: ft25
        },
        {
            id: 26,
            imgSrc: ft26,
            imgSrcSmall: ft26
        },
        {
            id: 27,
            imgSrc: ft27,
            imgSrcSmall: ft27
        },
        {
            id: 28,
            imgSrc: ft28,
            imgSrcSmall: ft28
        },
        {
            id: 29,
            imgSrc: ft29,
            imgSrcSmall: ft29
        },
        {
            id: 30,
            imgSrc: ft30,
            imgSrcSmall: ft30
        },
        {
            id: 31,
            imgSrc: ft31,
            imgSrcSmall: ft31
        },
        {
            id: 32,
            imgSrc: ft32,
            imgSrcSmall: ft32
        },
        {
            id: 33,
            imgSrc: ft33,
            imgSrcSmall: ft33
        },
        {
            id: 34,
            imgSrc: ft34,
            imgSrcSmall: ft34
        },
        {
            id: 35,
            imgSrc: ft35,
            imgSrcSmall: ft35
        },
        {
            id: 36,
            imgSrc: ft36,
            imgSrcSmall: ft36
        },
        {
            id: 37,
            imgSrc: ft37,
            imgSrcSmall: ft37
        },
        {
            id: 38,
            imgSrc: ft38,
            imgSrcSmall: ft38
        },
        {
            id: 39,
            imgSrc: ft39,
            imgSrcSmall: ft39
        },
        {
            id: 40,
            imgSrc: ft40,
            imgSrcSmall: ft40
        },
    ]

    const [model, setModel] = useState(false);
    const [bigImgSrc, setBigImgSrc] = useState('');

    function openImg(imgSrc) {
        setBigImgSrc(imgSrc);
        setModel(true)
    }

    function closeImg() {
        setBigImgSrc('');
        setModel(false)
    }

    return (
        <div className="gallery-container">

            <Nav></Nav>

            <div className="gallery-banner">
                <div className="gallery-title">Santa Isabel<br></br><span className="gallery-title-color">Galería de fotos</span></div>
            </div>

            <div onClick={() => closeImg()} className={model ? "gallery-modal-open" : "gallery-modal"}>
                <img
                    src={bigImgSrc}
                    alt='Cargando...'></img>

            </div>

            <div className="gallery-images-container">
                {
                    data && data.map((item, index) => {
                        return (
                            <div onClick={() => openImg(item.imgSrc)} className="gallery-image-container" key={index}>
                                <LazyLoad>
                                    <img style={{ width: '100%' }} src={item.imgSrcSmall} alt="Cargando..." />
                                </LazyLoad>
                            </div>

                        )
                    })
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Gallery;