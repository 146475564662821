/* eslint-disable no-useless-escape */

export function ValidateBranch(input) {

  const errors = {};

  let regex = /^(?=.*[A-Za-z ñ Ñ\u00C0-\u00FF])[A-Za-z ñ Ñ\u00C0-\u00FF]{3,60}$/

  if (!regex.test(input.province)) {
    errors.province = "La provincia debe tener entre 3 y 60 caracteres y solo contener letras";
  } else
    if (!/^[a-zA-Z\u00C0-\u00FF ]{3,60}$/.test(input.city)) {
      errors.city = "La ciudad debe tener entre 3 y 60 caracteres y solo contener letras y numeros";
    } else
      if (!/^[a-zA-Z\u00C0-\u00FF \d|\.|\, ]{3,60}$/.test(input.address)) {
        errors.address = "La dirección debe tener entre 3 y 60 caracteres y solo contener letras y numeros";
      } else
        if (!/^\d+$/.test(input.zipCode)) {
          errors.zipCode = "El codigo postal solo debe contener numeros.";
        } 
          
  return errors;
}