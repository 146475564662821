import React from "react";
import "./Home.scss"
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import HomeLanding from "./HomeLanding/HomeLanding";
import Intro from "./Intro/Intro";
import Explore from "./Explore/Explore";
import Featured from "./Featured/Featured";
import Services from "./Services/Services";
import About from "./About/About";
import SliderHome from "./SliderHome/SliderHome";

function Home() {
    return (
        <div className="home-container">
            <Nav></Nav>
            <HomeLanding></HomeLanding> 
            <Intro></Intro>
            <About></About>
            <SliderHome></SliderHome>
            <Services></Services>
            <Explore></Explore>
            <Featured></Featured>
            <Footer></Footer>
        </div>
    )
}

export default Home;