import React, { useState } from "react";
import "./LoginModal.scss"
import axios from "axios";
import { validate } from "../../validations/validations";

function LoginModal({ closeModal }) {

    function goBack() {
        closeModal()
    }

    const [input, setInput] = useState({
        email: "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
        setErrors(validate({ ...input, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post("https://www.viverosantaisabel.com/api/employee/recover", input);
            setInput({ email: "" });
            closeModal()
        } catch (e) {
            if (e.response.data.msg === "Not Acceptable") setErrors({ password: "Datos incorrectos" })
            setInput({
                email: "",
            });
        }
    };


    return (
        <div className="login-modal-container">

            <button className="login-modal-go-back" onClick={goBack}>Volver</button>

            <form className="login-modal-form" onSubmit={(e) => handleSubmit(e)}>

                <div className="login-modal-input-container">

                    <label className="login-modal-input-label">Ingresa tu correo electronico</label>

                    <input
                        className="login-modal-input"
                        placeholder="Ingresa tu correo electronico..."
                        name="email"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={input.email}
                    ></input>

                    {errors.email && (
                        <div className="login-modal-error">{errors.email}</div>
                    )}
                </div>

                <button className={
                        input.email === "" ||
                        errors.email ?
                        "login-modal-submit-btn-disabled" : "login-modal-submit-btn"}
                    type="submit">
                    Recuperar contraseña
                </button>

            </form>
        </div>
    )
}

export default LoginModal;