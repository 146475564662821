/* eslint-disable no-useless-escape */

export function ValidateUser(input) {

  const errors = {};

  let regex = /^(?=.*[A-Za-z ñ Ñ\u00C0-\u00FF])[A-Za-z ñ Ñ\u00C0-\u00FF]{3,60}$/

  if (!regex.test(input.firstName)) {
    errors.firstName = "El nombre debe tener entre 3 y 60 caracteres y solo contener letras";
  } else
    if (!regex.test(input.lastName)) {
      errors.lastName = "El apellido debe tener entre 3 y 60 caracteres y solo contener letras";
    } else
    if (!regex.test(input.province)) {
      errors.province = "La provincia debe tener entre 3 y 60 caracteres y solo contener letras";
    }
     else
        if (!regex.test(input.city)) {
          errors.city = "La ciudad debe tener entre 3 y 60 caracteres y solo contener letras";
        } else
            if (!/^\d+$/.test(input.zipCode)) {
              errors.zipCode = "El codigo postal solo debe contener numeros.";
            } else
              if (
                !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(input.email)) {
                errors.email = "Ingresa un correo electronico valido";
              }

  return errors;
}