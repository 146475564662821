import React from "react";
import "./SliderHome.scss"
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"
import "swiper/css/navigation"

import { EffectCoverflow, Pagination, Navigation } from "swiper";

import img1 from './../../../resources/images/gallery/ft20.webp'
import img2 from './../../../resources/images/gallery/ft9.webp'
import img3 from './../../../resources/images/gallery/ft10.webp'
import img4 from './../../../resources/images/gallery/ft11.webp'
import img5 from './../../../resources/images/gallery/ft12.webp'
import img6 from './../../../resources/images/gallery/ft13.webp'

function SliderHome() {
    return (
        <div className="slider-home-container">

            <div className="slider-home-text-container">
                <div className="slider-home-title">Galería Santa Isabel</div>
                <div className="slider-home-text">¡Visitá nuestra galería de imágenes y descubrí nuestras plantaciones y cultivos!</div>
                <a className="slider-home-text-a" href="/galeria"><button className="slider-home-text-btn">Ver galeria</button></a>
            </div>

            <div className="slider-home-slider-container">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={'3'}
                    coverflowEffect={
                        {
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2.5,
                        }
                    }
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={
                        {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            clickable: true,
                        }
                    }
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="swiper-container"

                >
                    <SwiperSlide>
                        <img className="swiper-img" src={img1} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img2} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img3} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img4} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img5} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img6} alt="Cargando"></img>
                    </SwiperSlide>

                    <div className="slider-tip">Arrastra las imágenes para seguir viendo o visitá nuestra galería</div>


                    <div className="swiper-interface">
                        <div className="slider-controller">
                            <div className="swiper-button-prev slider-arrow"></div>
                        </div>

                        <div className="swiper-pagination"></div>

                        <div className="slider-controller">
                            <div className="swiper-button-next slider-arrow"></div>
                        </div>
                    </div>

                </Swiper>
            </div>

            <div className="slider-home-slider-mobile-container">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={'2'}
                    coverflowEffect={
                        {
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2,
                        }
                    }
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={
                        {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            clickable: true,
                        }
                    }
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="swiper-container"

                >
                    <SwiperSlide>
                        <img className="swiper-img" src={img1} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img2} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img3} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img4} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img5} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={img6} alt="Cargando"></img>
                    </SwiperSlide>

                    <div className="slider-tip">Arrastra la imágenes para seguir viendo o visitá nuestra galería</div>


                    <div className="swiper-interface">
                        <div className="slider-controller">
                            <button className="swiper-button-prev slider-arrow"></button>
                        </div>

                        <div className="swiper-pagination"></div>

                        <div className="slider-controller">
                            <button className="swiper-button-next slider-arrow"></button>
                        </div>
                    </div>

                </Swiper>
            </div>
        </div>
    )
}

export default SliderHome;