import React, { useState } from "react";
import "./AddBranch.scss"
import { ValidateBranch } from "../../../validations/ValidateBranch";
import axios from "axios";


function AddBranch({ GoBack }) {


    const [input, setInput] = useState({
        province: "",
        city: "",
        address: "",
        zipCode: "",
        iFrameUrl: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        e.preventDefault()
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
        setErrors(ValidateBranch({ ...input, [e.target.name]: e.target.value }));
    };

    const postBranch = async (e) => {
        e.preventDefault();
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            await axios.post("https://www.viverosantaisabel.com/api/branch/", {

                province: input.province,
                city: input.city,
                address: input.address,
                zipCode: input.zipCode,
                iFrameUrl: input.iFrameUrl

            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }
            );

            GoBack()

        } catch (err) {
        }
    };

    return (
        <div className="add-branch-container">

            {/* <button className="add-branch-go-back" onClick={GoBack}>Volver</button> */}

            <div className="add-branch-title">Crea una sucursal</div>

            <form className="add-branch-form" onSubmit={(e) => postBranch(e)}>

                <div className="add-branch-input-container">
                    <label className="add-branch-input-label">Provincia</label>
                    <input
                       onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                        className="add-branch-input"
                        placeholder="Provincia de la sucursal..."
                        name="province"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                    ></input>

                    {errors.province && (
                        <div className="add-branch-error">{errors.province}</div>
                    )}
                </div>

                <div className="add-branch-input-container">
                    <label className="add-branch-input-label">Ciudad</label>
                    <input
                       onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                        className="add-branch-input"
                        placeholder="Ciudad de la sucursal..."
                        name="city"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                    ></input>

                    {errors.city && (
                        <div className="add-branch-error">{errors.city}</div>
                    )}
                </div>

                <div className="add-branch-input-container">
                    <label className="add-branch-input-label">Dirección</label>
                    <input
                       onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                        className="add-branch-input"
                        placeholder="Dirección de la sucursal..."
                        name="address"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                    ></input>

                    {errors.address && (
                        <div className="add-branch-error">{errors.address}</div>
                    )}
                </div>

                <div className="add-branch-input-container">
                    <label className="add-branch-input-label">Codigo postal</label>
                    <input
                       onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                        className="add-branch-input"
                        placeholder="Codigo postal de la sucursal..."
                        name="zipCode"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                    ></input>

                    {errors.zipCode && (
                        <div className="add-branch-error">{errors.zipCode}</div>
                    )}
                </div>

                <div className="add-branch-input-container">
                    <label className="add-branch-input-label">Enlace a la ubicación en Google Maps</label>
                    <input
                       onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                        className="add-branch-input"
                        placeholder="Enlace a la ubicación en Google Maps..."
                        name="iFrameUrl"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                    ></input>
                </div>

                <button className={
                        input.province === "" ||
                        input.city === "" ||
                        input.address === "" ||
                        input.zipCode === "" ||
                        errors.province ||
                        errors.city ||
                        errors.address ||
                        errors.zipCode ? "add-branch-submit-btn-disabled" : "add-branch-submit-btn"} type="submit">Crear sucursal</button>

            </form>

        </div>
    )
}

export default AddBranch;