import React, { useState } from "react";
import "./NavSimple.scss"
import logo from '../../resources/images/onlylogotransparent.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";

function NavSimple() {

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }


    return (
        <nav className='nav-general-container-active'>

            {
                openModal && (
                    <div className="nav-mobile-modal-general-container">
                        <div className="nav-mobile-modal-container">
                            <div className="nav-a-container-mobile">
                                <a className="nav-a-mobile" href="/">Home</a>
                                <a className="nav-a-mobile" href="/productos">Catalogo</a>
                                <a className="nav-a-mobile" href="/galeria">Galeria</a>
                                <a className="nav-a-mobile" href="/noticias">Noticias</a>
                                <a className="nav-a-mobile" href="/historia">Historia</a>
                                <a className="nav-a-mobile" href="/contacto">Contacto</a>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="nav-container">
                
                <a href="/" className="nav-logo-container">
                    <img className="nav-logo" src={logo} alt="Cargando..."></img>
                    <div className="nav-logo-text">Vivero <span className="nav-logo-text-color">Santa Isabel</span> S.A</div>
                </a>

                {
                    !openModal && (

                        <button onClick={() => handleOpenModal()} className='nav-mobile-btn'>
                            <FontAwesomeIcon icon={faBars} className='nav-mobile-icon' />
                            <div>Menu</div>
                        </button>
                    )
                }
                {
                    openModal && (

                        <button onClick={() => handleCloseModal()} className='nav-mobile-btn'>
                            <FontAwesomeIcon icon={faBars} className='nav-mobile-icon' />
                            <div>Menu</div>
                        </button>
                    )
                }

                <div className="nav-a-container">
                    <a className="nav-a" href="/">Home</a>
                    <a className="nav-a" href="/productos">Catalogo</a>
                    <a className="nav-a" href="/galeria">Galeria</a>
                    <a className="nav-a" href="/noticias">Noticias</a>
                    <a className="nav-a" href="/historia">Historia</a>
                    <a className="nav-a" href="/contacto">Contacto</a>
                </div>
            </div>
        </nav>
    )
}

export default NavSimple;