import React, { useEffect, useState } from "react";
import "./PostsList.scss";
import axios from "axios";
import logo from '../../../resources/images/footerlogo.webp'
import spinner from "../../../resources/images/gallery/spinner.gif"

function PostsList() {

  // Spinner state
  const [isLoading, setIsLoading] = useState(false);

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const getPosts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://www.viverosantaisabel.com/api/post?page=${currentPage}`
        );
        const data = response.data.data;
        setPosts(data);
        setTotalPages(response.data.totalPages);
      } catch (err) {
      }
      setIsLoading(false);
    };

    getPosts();
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageButtons = () => {
    const maxButtons = 5;
    const halfButtons = Math.floor(maxButtons / 2);
    const startPage = Math.max(currentPage - halfButtons, 1);
    const endPage = Math.min(startPage + maxButtons - 1, totalPages);

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`posts-list-pagination-btn-number ${i === currentPage ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="post-list-general-container">

      {isLoading ? (
        <div className="spinner-container">
          <img alt="Cargando..." src={spinner} className="spinner"></img>
        </div>
      ) : (
        <div className="post-list-once-rendered-container">
          {posts.length > 0 ? (
            <div className="posts-list-container">
              {posts.map((post) => (
                <a
                  key={post.post_id}
                  href={`/noticias/${post.post_id}`}
                  className="posts-list-single-container"
                >
                  <img
                    className="posts-list-single-img"
                    key={post.post_id}
                    src={
                      post.thumbnail
                        ? URL.createObjectURL(
                          new Blob([new Uint8Array(post.thumbnail.data)], {
                            type: "image/png",
                          })
                        )
                        : ""
                    }
                    alt={post.title}
                  ></img>
                  <div className="posts-list-signle-title">{post.title}</div>
                  <div className="posts-list-single-subtitle">
                    {post.text.slice(0, 70)}...
                  </div>
                </a>
              ))}

              <div className="posts-list-pagination-container">
                <button
                  className="posts-list-pagination-btn"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  Anterior
                </button>

                <div className="posts-list-pagination-btn-number-container">
                  {renderPageButtons()}
                </div>

                <button
                  className="posts-list-pagination-btn"
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  Siguiente
                </button>
              </div>
            </div>
          ) : (
            <div className="post-featured-no-posts">
              <img className="post-featured-no-posts-img" src={logo} alt="Cargando..."></img>
              <div className="post-featured-no-posts-text">¡Ahora mismo no tenemos noticias!</div>
            </div>
          )}
        </div>
      )}

    </div>
  );
}

export default PostsList;
