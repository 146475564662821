import React from "react";
import "./Explore.scss"
import Rosales from '../../../resources/images/Rosales.webp'
import Arboles from '../../../resources/images/Arboles.webp'
import Arbustos from '../../../resources/images/Arbustos.webp'
import example4 from '../../../resources/images/Cactus.webp'
import example5 from '../../../resources/images/example6.webp'
import example6 from '../../../resources/images/gallery/ft9.webp'


function Explore() {
    return (
        <div className="explore-container">
            <div className="explore-title-container">
                <div className="explore-title">Nuestros <span className="explore-title-color">Productos</span></div>
                <a className="explore-btn" href="/productos">Mirá nuestro catálogo</a>
            </div>

            <div className="explore-menu-container">

                <div className="explore-menu-left-column">

                    <div className="explore-menu-left-column-top-row">

                        <a href="/productos/4" className="explore-menu-left-img-container-top">
                            <div className="explore-menu-img-title">ROSALES</div>
                            <img className="explore-menu-left-column-top-row-img" src={Rosales} alt="Cargando..."></img>
                        </a>

                        <a href="/productos/1" className="explore-menu-left-img-container-top">
                            <div className="explore-menu-img-title">ÁRBOLES</div>
                            <img className="explore-menu-left-column-top-row-img" src={Arboles} alt="Cargando..."></img>
                        </a>

                    </div>

                    <div className="explore-menu-left-column-bottom-row">

                        <a href="/productos/2" className="explore-menu-left-img-container-bottom">
                            <div className="explore-menu-img-title">ARBUSTOS</div>
                            <img className="explore-menu-left-column-bottom-row-img" src={Arbustos} alt="Cargando..."></img>
                        </a>

                    </div>
                </div>

                <div className="explore-menu-right-column">
                    <div className="explore-menu-right-column-left">

                        <a href="/productos/11" className="explore-menu-right-img-container-left">
                            <div className="explore-menu-img-title">PALMERAS</div>
                            <img className="explore-menu-right-left-img" src={example5} alt="Cargando..."></img>
                        </a>

                    </div>
                    <div className="explore-menu-right-column-right">

                        <a href="/productos/7" className="explore-menu-right-img-container-right">
                        <div className="explore-menu-img-title">CACTUS</div>
                            <img className="explore-menu-right-column-right-img" src={example4} alt="Cargando..."></img>
                        </a>

                        <a href="/productos/10" className="explore-menu-right-img-container-right">
                        <div className="explore-menu-img-title">DECO & HOME</div>
                            <img className="explore-menu-right-column-right-img" src={example6} alt="Cargando..."></img>
                        </a>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Explore;