import React from "react";
import "./Posts.scss"
import Nav from '../Nav/Nav'
import Footer from '../Footer/Footer'
import SliderPosts from "../SliderPosts/SliderPosts";
import PostsList from "./PostsList/PostsList";

function Posts() {
    return (
        <div className="posts-general-container">
            <Nav></Nav>

            <div className="posts-title-container">
                <div className="posts-title">Santa Isabel <span className="posts-title-color">Noticias y Novedades</span></div>
            </div>

            
            <div className="post-list-title">Noticias destacadas</div>
            <div className="post-list-text">Encontrá acá las últimas novedades de Vivero Santa Isabel</div>

            <div className="posts-columns-container">
                <div className="posts-slider-container">
                    <SliderPosts></SliderPosts>
                </div>
            </div>

            <div className="post-list-title">Todas las noticias</div>
            <div className="post-list-text">¡Enterate de las últimas novedades para no perderte ofertas, eventos especiales y mucho más!</div>

            <div className="post-render-container">
                <PostsList></PostsList>
            </div>

            

            <Footer></Footer>

        </div>
    )
}

export default Posts;