import React from "react";
import "./CurrentBoard.scss"
import PostMenu from "../PostMenu/PostMenu";
import AddProduct from "../ProductAdd/AddProduct";
import ShowProducts from "../ProductMenu/ShowProducts";
import AddBranch from "../BranchAdd/AddBranch";
import ShowBranches from "../BranchMenu/ShowBranches";
import AddPost from "../PostAdd/AddPost";
import AddUser from "../UserAdd/AddUser";
import ShowUsers from "../UserMenu/ShowUsers";

function CurrentBoard({ current, currentForm, ChangeCurrentForm, GoBack }) {

    return (

        <div className="current-board-container">

            {
                current === "products" && currentForm === '' && (
                    <div className="current-menu">

                        <div className="current-header-container">

                            <div className="current-title-container">
                                <div className="current-title">Productos</div>
                            </div>

                            <div className="current-items-container">

                                <div id="add-product" onClick={(e) => ChangeCurrentForm(e)} className="current-item">Nuevo producto
                                </div>

                            </div>

                        </div>

                        <ShowProducts></ShowProducts>


                    </div>
                )
            }

            {
                current === "posts" && currentForm === '' && (
                    <div className="current-menu">

                        <div className="current-header-container">

                            <div className="current-title-container">
                                <div className="current-title">Noticias</div>
                            </div>

                            <div className="current-items-container">
                                <div id="add-post" onClick={(e) => ChangeCurrentForm(e)} className="current-item">Nueva noticia
                                </div>
                            </div>
                        </div>

                        <div className="current-post-list-container">
                            <PostMenu></PostMenu>
                        </div>

                    </div>
                )
            }

            {
                current === "contact" && currentForm === '' && (

                    <div className="current-menu">

                        <div className="current-header-container">

                            <div className="current-title-container">
                                <div className="current-title">Contacto</div>
                            </div>

                            <div className="current-items-container">

                                <div id="add-branch" onClick={(e) => ChangeCurrentForm(e)} className="current-item">Nueva sucursal
                                </div>

                            </div>
                        </div>

                        <ShowBranches></ShowBranches>

                    </div>
                )
            }

            {
                current === "users" && currentForm === '' && (
                    <div className="current-menu">

                        <div className="current-header-container">

                            <div className="current-title-container">
                                <div className="current-title">Administrar usuarios</div>
                            </div>


                            <div className="current-items-container">

                                <div id="add-user" onClick={(e) => ChangeCurrentForm(e)} className="current-item">Nuevo usuario
                                </div>

                            </div>

                        </div>

                        <ShowUsers></ShowUsers>

                    </div>
                )
            }

            {
                currentForm !== '' && (
                    <div className="current-board-form">

                        {
                            currentForm === "add-product" && (
                                <AddProduct GoBack={GoBack}></AddProduct>
                            )
                        }

                        {
                            currentForm === "add-post" && (
                                <AddPost GoBack={GoBack}></AddPost>
                            )
                        }

                        {
                            currentForm === "add-branch" && (
                                <AddBranch GoBack={GoBack}></AddBranch>
                            )
                        }

                        {
                            currentForm === "add-user" && (
                                <AddUser GoBack={GoBack}></AddUser>
                            )
                        }


                    </div>
                )
            }

        </div>
    )
}

export default CurrentBoard;