import React, { useEffect, useState } from "react";
import "./Post.scss";
import axios from "axios";
import NavSimple from "../../NavSimple/NavSimple";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import spinner from "../../../resources/images/gallery/spinner.gif"
import logo from '../../../resources/images/footerlogo.webp'
import YoutubeEmbed from "../YoutubeEmbed/YoutubeEmbed";

function Post() {

    // Spinner state
    const [isLoading, setIsLoading] = useState(false);

    let params = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState({});
    const [featuredPosts, setFeaturedPosts] = useState([]);
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        const getPost = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `https://www.viverosantaisabel.com/api/post/${params.id}`
                );
                const data = response.data.data;
                setPost(data);
            } catch (err) {
                navigate('/noticias')
            }

            try {
                const response = await axios.get(
                    `https://www.viverosantaisabel.com/api/post/featured`
                );
                const data = response.data.data;
                setFeaturedPosts(data);
            } catch (err) {
            }

            try {
                const response = await axios.get("https://www.viverosantaisabel.com/api/branch/");
                const data = response.data.data;
                setBranches(data);
            } catch (err) {
            }
            setIsLoading(false);
        };
        getPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return "";
        }

        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
        };

        const formatter = new Intl.DateTimeFormat("es-ES", options);
        const formattedDate = formatter.format(date);
        return formattedDate;
    };

    return (
        <div className="post-general-container">

            <NavSimple></NavSimple>

            {isLoading ? (
                <div className="spinner-container">
                    <img alt="Cargando..." src={spinner} className="spinner"></img>
                </div>
            ) : (
                <div className="post-container-once-rendered">
                    <div className="post-featured-container">

                        <div className="post-featured-title">Noticias destacadas</div>

                        {featuredPosts.length > 0 ? (
                            featuredPosts.map((post) => {
                                return (
                                    <a key={post.post_id} href={`/noticias/${post.post_id}`} className="post-featured-single-container">

                                        <img
                                            className="post-featured-single-img"
                                            key={post.post_id}
                                            src={post.thumbnail ? URL.createObjectURL(new Blob([new Uint8Array(post.thumbnail.data)], { type: 'image/png' })) : ''}
                                            alt={post.title}>
                                        </img>

                                        <div className="post-featured-single-title">{post.title}</div>
                                        <div className="post-featured-single-subtitle">{post.text.slice(0, 70)}...</div>

                                    </a>
                                )
                            })
                        ) : (
                            <div className="post-featured-no-posts">
                                <img className="post-featured-no-posts-img" src={logo} alt="Cargando..."></img>
                                <div className="post-featured-no-posts-text">¡Ahora mismo no tenemos noticias destacadas!</div>
                            </div>
                        )}

                        <div className="post-featured-title">¡Contactanos!</div>

                        {
                            branches && branches.map((e, key) => {
                                return (
                                    <div className="post-featured-contact-container" key={key}>

                                        <div className="post-featured-text-container">
                                            <div className="post-featured-city">{e.city}, {e.province}</div>
                                            <div className="post-featured-address">{e.address}</div>
                                            {
                                                e.branch_phone.map((e, key) => {
                                                    return (
                                                        <div key={key} className="post-featured-single-data"><span className="post-featured-single-data-span">Telefono: </span>+54 {e.area_code} {e.number}</div>
                                                    )
                                                })
                                            }
                                            {
                                                e.branch_email.map((e, key) => {
                                                    return (
                                                        <div key={key} className="post-featured-single-data"><span className="post-featured-single-data-span">Email: </span>{e.email}</div>
                                                    )
                                                })
                                            }
                                        </div>


                                    </div>
                                )

                            })
                        }

                    </div>

                    {post && (
                        <div className="post-container">

                            <div className="post-title">{post.title}</div>
                            <div className="post-header">Noticias <span className="post-header-color">Santa Isabel</span></div>
                            <div className="post-date">Fecha de publicación: {formatDate(post.publication_date)}</div>

                            <img
                                className="post-thumbnail-img"
                                key={post.post_id}
                                src={
                                    post.thumbnail
                                        ? URL.createObjectURL(
                                            new Blob([new Uint8Array(post.thumbnail.data)], {
                                                type: "image/png",
                                            })
                                        )
                                        : ""
                                }
                                alt={post.title}
                            ></img>


                            <div className="post-text">{post.text}</div>

                            {post.galery && post.galery.video && post.galery.video.external_url && (
                                <YoutubeEmbed url={post.galery.video[0].external_url}></YoutubeEmbed>
                            )}

                            {post.galery && post.galery.image && (
                                <div className="post-images-container">
                                    {post.galery.image.map((e) => {
                                        return (
                                            <img
                                                style={{ width: "100%", marginBottom: "2vh" }}
                                                key={e.image_id}
                                                src={URL.createObjectURL(
                                                    new Blob([new Uint8Array(e.data.data)], {
                                                        type: "image/png",
                                                    })
                                                )}
                                                alt="Cargando..."
                                            ></img>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}


        </div>
    );
}

export default Post;