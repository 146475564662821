import React, { useEffect, useState } from "react";
import "./ShowUsers.scss";
import UserEdit from "../UserEdit/UserEdit";
import axios from "axios";

function ShowUsers() {

    // Get users list

    const [users, setUsers] = useState([]);

    const GetUsers = async () => {
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);
        try {
            const response = await axios.get("https://www.viverosantaisabel.com/api/employee/", {
                headers: { Authorization: `Bearer ${userToken}` }
            });
            const data = response.data;
            setUsers(data.data);
        } catch (err) {
        }
    };

    const DeleteUser = async (employeeId) => {
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);
        const id = employeeId;
        try {
            await axios.post("https://www.viverosantaisabel.com/api/employee?_method=DELETE", {
                employeeId: id
            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }
            );
        } catch (err) {
            setUsers([])
        }

        GetUsers();
    };

    // Pagination states and functions

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        const GetUsersPagination = async () => {
            const userCredentials = window.localStorage.getItem("userCredentials");
            const userToken = JSON.parse(userCredentials);
            try {
                const response = await axios.get(`https://www.viverosantaisabel.com/api/employee?page=${currentPage}`, {
                    headers: { Authorization: `Bearer ${userToken}` }
                });
                const data = response.data;
                setUsers(data.data);
                setTotalPages(data.totalPages);
            } catch (err) {
            }
        };
        GetUsersPagination();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])


    const handlePrevPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageButtons = () => {
        const maxButtons = 5;
        const halfButtons = Math.floor(maxButtons / 2);
        const startPage = Math.max(currentPage - halfButtons, 1);
        const endPage = Math.min(startPage + maxButtons - 1, totalPages);

        const buttons = [];
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    className={`user-menu-pagination-btn-number ${i === currentPage ? "active" : ""}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }

        return buttons;
    };


    // Edit user modal management

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState("");


    function OpenModal(userId) {
        setModalData(userId)
        setOpenModal(true)
    }

    function CloseModal() {
        setOpenModal(false)
        GetUsers();
    }


    return (

        <div className="show-users-container">

            {
                openModal &&
                <div className="user-menu-modal-container">
                    <UserEdit
                        closeModal={CloseModal}
                        userId={modalData}
                    >
                    </UserEdit>
                </div>
            }

            {
                users.length > 0 && users.map((e, index) => {
                    return (
                        <div className="show-users-single-container" key={index}>
                            <div className="show-users-single-title-container">
                                <div className="show-users-single-title">{e.first_name} {e.last_name}</div>
                                <div className="show-users-single-btns-container">
                                    <button className="show-users-single-btn-edit" onClick={() => OpenModal(e.employee_id)}>Editar información</button>

                                    <button onClick={() => DeleteUser(e.employee_id)} className="show-users-single-btn-delete">Eliminar</button>

                                </div>
                            </div>

                            <div className="show-users-single-data-container">

                                <div className="show-users-single-data"><span className="show-users-single-data-span">Email: </span>{e.email}</div>

                                <div className="show-users-single-data"><span className="show-users-single-data-span">Provincia: </span>{e.province}</div>

                                <div className="show-users-single-data"><span className="show-users-single-data-span">Ciudad: </span>{e.city}</div>

                                <div className="show-users-single-data"><span className="show-users-single-data-span">Codigo postal: </span>{e.zip_code}</div>

                            </div>

                        </div>
                    )

                })
            }

            <div className="user-menu-pagination-container">
                <button
                    className="user-menu-pagination-btn"
                    disabled={currentPage === 1}
                    onClick={handlePrevPage}
                >
                    Anterior
                </button>

                <div className="user-menu-pagination-btn-number-container">
                    {renderPageButtons()}
                </div>

                <button
                    className="user-menu-pagination-btn"
                    disabled={currentPage === totalPages}
                    onClick={handleNextPage}
                >
                    Siguiente
                </button>
            </div>

        </div>
    )
}

export default ShowUsers;