/* eslint-disable no-useless-escape */

export function ValidateBranchEmail(emailInput) {

  const errors = {};
  
  if (
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(emailInput.email)) {
      errors.email = "Ingresa un correo electronico valido";
  }

  return errors;
}