import React, { useEffect, useState } from "react";
import "./Contact.scss";
import axios from "axios";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function Contact() {
  // useState for input content
  const [input, setInput] = useState({
    clientName: "",
    clientEmail: "",
    branchEmail: "",
    message: "",
    button: "Enviar mensaje",
  });

  // function for input value change and validation
  const handleChange = (e) => {
    setInput({
      ...input,
      button: "Enviar mensaje",
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(input)
    const formData = new FormData();

    formData.append("clientName", input.clientName);
    formData.append("clientEmail", input.clientEmail);
    formData.append("branchEmail", input.branchEmail);
    formData.append("message", input.message);

    try {
      const response = await axios.post(
        "https://www.viverosantaisabel.com/api/contact",
        formData,
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(response.data)
      setInput({
        clientName: "",
        clientEmail: "",
        branchEmail: "",
        message: "",
        button: "¡Enviado!",
      })
    } catch (error) {
      console.log(error)
      setInput({
        clientName: "",
        clientEmail: "",
        branchEmail: "",
        message: "",
        button: "Hubo un error, intentalo mas tarde"
      });
    }
  };

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const getBranches = async () => {
      try {
        const response = await axios.get(
          "https://www.viverosantaisabel.com/api/branch/"
        );
        const data = response.data.data;
        setBranches(data);
        console.log(branches)
      } catch (err) { }
    };

    getBranches();
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contact-general-container">
      <Nav></Nav>
      <div className="contact-container">

        <div className="contact-title-container">
          <div className="contact-title">
            Santa Isabel <span className="contact-title-color">Información de Contacto</span>
          </div>
        </div>

        <div className="contact-sub-title">¡Envianos un mensaje!</div>
        <div className="contact-text">¡Consultanos cualquier duda que tengas, te respondemos a la brevedad!</div>

        <form className="contact-form" onSubmit={(e) => handleSubmit(e)}>

          <div className="contact-form-left">

            <div className="contact-form-input-container">
              <label className="contact-form-input-label">Seleccioná la sucursal</label>
              <select
                name="branchEmail"
                className="contact-form-select"
                value={input.branchEmail}
                onChange={(e) => handleChange(e)}
              >
                <option value="">Seleccioná un email</option>
                {branches.map((branch) => (
                  <optgroup
                    className="contact-form-input"
                    key={branch.branch_id}
                    label={branch.city}
                  >
                    {branch.branch_email.length > 0 ? (
                      branch.branch_email.map((email) => (
                        <option key={email.email_id} value={email.email}>
                          <FontAwesomeIcon icon={faEnvelope} className="icon" />
                          {email.email}
                        </option>
                      ))
                    ) : (
                      <option disabled>Sin contacto actualmente</option>
                    )}
                  </optgroup>
                ))}
              </select>

            </div>


            <div className="contact-form-input-container">
              <label className="contact-form-input-label">Tu nombre</label>
              <input
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                className="contact-form-input"
                placeholder="Ingresa tu nombre"
                name="clientName"
                value={input.clientName}
                autoComplete="off"
                onChange={(e) => handleChange(e)}
              ></input>
            </div>

            <div className="contact-form-input-container">
              <label className="contact-form-input-label">Tu correo electrónico</label>
              <input
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                value={input.clientEmail}
                className="contact-form-input"
                placeholder="Ingresa tu correo electronico"
                name="clientEmail"
                autoComplete="off"
                type="email"
                onChange={(e) => handleChange(e)}
              ></input>
            </div>

          </div>

          <div className="contact-form-right">
            <div className="contact-form-input-container">
              <label className="contact-form-input-label">Tu mensaje</label>
              <label className="contact-form-input-label-small">Para generar un salto de línea presiona Mayús + Enter</label>
              <textarea
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                className="contact-form-input-textarea"
                placeholder="Escribí tu mensaje"
                name="message"
                value={input.message}
                autoComplete="off"
                onChange={(e) => handleChange(e)}
              ></textarea>

            </div>
          </div>



          <button
            className={
              input.clientName.length < 2 ||
                input.clientEmail.length < 5 ||
                !input.branchEmail ||
                input.message.length < 2 ||
                input.message.length > 3500 ? "contact-form-submit-btn-disabled" : "contact-form-submit-btn"}
            type="submit"
          >{input.button}</button>

        </form>

        <div className="contact-sub-title">¡Visitá nuestras sucursales!</div>
        <div className="contact-text">Te invitamos a descubrir nuestras sucursales alrededor de la Argentina.</div>

        <div className="contact-top-branches-container">
          {branches &&
            branches.map((e, key) => {
              return (
                <div className="contact-top-branch-single" key={key}>
                  <div className="contact-top-branch-single-iframe">
                    <iframe
                      title={key}
                      className="iframe"
                      src={e.iframe_url}
                      height="100%"
                      width="100%"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>

                  <div className="contact-top-branch-text-container">
                    <div className="contact-top-branch-title-container">
                      <div className="contact-top-branch-city">{e.city}, {e.province}</div>
                      <div className="contact-top-branch-address">{e.address}</div>
                    </div>

                    <div className="contact-top-branch-sub-title">Información de contacto:</div>

                    <div className="contact-top-branch-maps-container">

                      {
                        e.branch_phone.length > 0 && (
                          <div className="contact-top-branch-map">
                            <div className="contact-top-branch-map-title">Teléfonos:</div>
                            {e.branch_phone.slice(0, 3).map((e, key) => {
                              return (
                                <div key={key} className="contact-top-branch-single-data">
                                  {
                                    e.phone_type.type_id === 1 && (
                                      <FontAwesomeIcon icon={faPhone} className="map-icon" />
                                    )
                                  }
                                  {
                                    e.phone_type.type_id === 2 && (
                                      <a target="_blank" rel="noreferrer"  className="contact-wpp-absolute" 
                                      href={`https://wa.me/+54${e.area_code + e.number}`}><FontAwesomeIcon icon={faWhatsapp} className="map-icon-wpp" /></a>
                                    )
                                  } 
                                  <a target="_blank" rel="noreferrer"  
                                  href={`tel:+54${e.area_code}${e.number}`} className={e.phone_type.type_id === 2 ? "contact-details-wpp": "contact-details"}>+54 {e.area_code} {e.number}</a>
                            
                                </div>
                              );
                            })}
                          </div>
                        )
                      }

                      <div className="contact-top-branch-map">
                        <div className="contact-top-branch-map-title">Redes:</div>

                        {
                          e.branch_id === 1 && (
                            <div className="contact-top-branch-single-data">
                              <FontAwesomeIcon className="map-icon" icon={faInstagram}></FontAwesomeIcon>
                              <a className="contact-top-branch-map-email" target="_blank" rel="noreferrer" href="https://www.instagram.com/viverosantaisabelsp/">viverosantaisabelsp</a>
                            </div>
                          )
                        }

                        {
                          e.branch_id === 1 && (
                            <div className="contact-top-branch-single-data">
                              <FontAwesomeIcon className="map-icon" icon={faFacebook}></FontAwesomeIcon>
                              <a className="contact-top-branch-map-email" target="_blank" rel="noreferrer" href="https://www.facebook.com/viverosantaisabelsp">viverosantaisabelsp</a>
                            </div>
                          )
                        }

                        {
                          e.branch_id === 2 && (
                            <div className="contact-top-branch-single-data">
                              <FontAwesomeIcon className="map-icon" icon={faInstagram}></FontAwesomeIcon>
                              <a className="contact-top-branch-map-email" target="_blank" rel="noreferrer" href="https://www.instagram.com/viverosantaisabelst/">viverosantaisabelst</a>
                            </div>
                          )
                        }

                        {
                          e.branch_id === 2 && (
                            <div className="contact-top-branch-single-data">
                              <FontAwesomeIcon className="map-icon" icon={faFacebook}></FontAwesomeIcon>
                              <a className="contact-top-branch-map-email" target="_blank" rel="noreferrer" href="https://www.facebook.com/viverosantaisabelst">viverosantaisabelst</a>
                            </div>
                          )
                        }

                        {
                          e.branch_id === 3 && (
                            <div className="contact-top-branch-single-data">
                              <FontAwesomeIcon className="map-icon" icon={faInstagram}></FontAwesomeIcon>
                              <a className="contact-top-branch-map-email" target="_blank" rel="noreferrer" href="https://www.instagram.com/viverosantaisabelvt/">viverosantaisabelvt</a>
                            </div>
                          )
                        }

                        {
                          e.branch_id === 3 && (
                            <div className="contact-top-branch-single-data">
                              <FontAwesomeIcon className="map-icon" icon={faFacebook}></FontAwesomeIcon>
                              <a className="contact-top-branch-map-email" target="_blank" rel="noreferrer" href="https://www.facebook.com/viverosantaisabelvenado">viverosantaisabelvenado</a>
                            </div>
                          )
                        }
                      </div>
                      {
                        e.branch_email.length > 0 && (
                          <div className="contact-top-branch-map">
                            <div className="contact-top-branch-map-title">Correos electrónicos:</div>
                            {e.branch_email.map((e, key) => {
                              return (
                                <div key={key} className="contact-top-branch-single-data">
                                  <FontAwesomeIcon className="map-icon" icon={faEnvelope}></FontAwesomeIcon>
                                  <a className="contact-top-branch-map-email" href={`mailto:${e.email}`}>{e.email}</a>
                                </div>
                              );
                            })}
                          </div>
                        )
                      }
                    </div>

                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
