import React, { useEffect, useState } from "react";
import "./SliderPosts.scss"
import axios from "axios"
import logo from '../../resources/images/footerlogo.webp'

function SliderPosts() {

    const [featuredPosts, setFeaturedPosts] = useState([]);

    useEffect(() => {
        const getPost = async () => {
            try {
                const response = await axios.get(
                    `https://www.viverosantaisabel.com/api/post/featured`
                );
                const data = response.data.data;
                setFeaturedPosts(data);
            } catch (err) {
            }
        };
        getPost();
    }, []);

    return (
        <div className="slider-p-container">

            <div className="featured-list-container">
                {featuredPosts.length > 0 ? (
                    featuredPosts && featuredPosts.map((post, index) => {
                        return (
                            <a key={post.post_id} href={`/noticias/${post.post_id}`} className="post-featured-single-container">
                                <input type='radio' className="slider-p-radio" name="images" id={`radio-${index}`} defaultChecked={index === 0 ? true : false}></input>
    
                                <div className="slider-p-slide" id={`slide-${index}`}>
                                    <img
                                        className="slider-p-img"
                                        src={post.thumbnail ? URL.createObjectURL(new Blob([new Uint8Array(post.thumbnail.data)], { type: 'image/png' })) : ''}
                                        alt='Cargando...'></img>
                                    <div className="slider-p-slide-title">{post.title}</div>
                                    <div className="slider-p-slide-text">{post.text.slice(0, 100)}</div>
                                </div>
                            </a>
                        )
                    })
                ) : (
                    <div className="post-featured-no-posts">
                        <img className="post-featured-no-posts-img" src={logo} alt="Cargando..."></img>
                        <div className="post-featured-no-posts-text">¡Ahora mismo no tenemos noticias destacadas!</div>
                    </div>
                )}
            </div>



            <div className="slider-p-dots">
                {
                    featuredPosts && featuredPosts.map((post, index) => {
                        return (
                            <label htmlFor={`radio-${index}`} id={`label-${index}`}></label>
                        )
                    })
                }
            </div>


        </div>
    )
}

export default SliderPosts;