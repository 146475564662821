import React, { useEffect, useState } from "react";
import "./ShowBranches.scss";
import axios from "axios";
import EditBranch from "../BranchEdit/EditBranch";
import EditBranchContact from "../BranchEditContact/EditBranchContact";
function ShowBranches() {

    const [branches, setBranches] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState("");

    const [openModalContact, setOpenModalContact] = useState(false);
    const [modalDataContact, setModalDataContact] = useState("");

    const getBranches = async () => {
        try {
            const response = await axios.get("https://www.viverosantaisabel.com/api/branch/");
            const data = response.data.data;
           
            setBranches(data);
        } catch (err) {
        }
    };

    useEffect(() => {
        getBranches();
    }, [])

    function OpenModal(e) {
        const branchId = e.target.value
        setModalData(branchId)
        setOpenModal(true)
    }

    function CloseModal() {
        setOpenModal(false)
        getBranches();
    }

    function OpenModalContact(e) {
        const branchId = e.target.value
        setModalDataContact(branchId)
        setOpenModalContact(true)
    }

    function CloseModalContact() {
        setOpenModalContact(false)
        getBranches();
    }

    const deleteBranch = async (e) => {
        const id = parseInt(e.target.value)
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            await axios.post("https://www.viverosantaisabel.com/api/branch?_method=DELETE",

                { branchId: id },

                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'content-type': 'application/json'
                    }
                }
            );
            getBranches();
            
        } catch (err) {
            setBranches([])
        }

    }

    return (

        <div className="show-branches-container">

            {
                openModal &&
                <div className="show-branches-modal-container">
                    <EditBranch
                        closeModal={CloseModal}
                        branchId={modalData}
                    >
                    </EditBranch>
                </div>
            }

            {
                openModalContact &&
                <div className="show-branches-modal-container">
                    <EditBranchContact
                        closeModal={CloseModalContact}
                        branchId={modalDataContact}
                    >
                    </EditBranchContact>
                </div>
            }

            {
                branches && branches.map((e, key) => {
                    return (
                        <div className="show-branches-single-container" key={key}>

                            <div className="show-branches-single-title-container">
                                <div className="show-branches-single-title">{e.city}, {e.province}</div>
                                <div className="show-branches-single-btns-container">

                                    <button onClick={(e) => OpenModal(e)} value={e.branch_id} className="show-branches-single-btn-edit">Editar datos</button>

                                    <button onClick={(e) => OpenModalContact(e)} value={e.branch_id} className="show-branches-single-btn-edit">Editar contacto</button>

                                    <button onClick={(e) => deleteBranch(e)} value={e.branch_id} className="show-branches-single-btn-delete">X</button>

                                </div>
                            </div>

                            <div className="show-branches-single-data-container">

                                <div className="show-branches-single-data">
                                    <span className="show-branches-single-data-span">Dirección: </span>{e.address}</div>
                                <div className="show-branches-single-data"><span className="show-branches-single-data-span">Codigo postal: </span>{e.zip_code}</div>

                                <div className="show-branches-single-data-map">
                                    {
                                        e.branch_phone.map((e, key) => {
                                            return (
                                                <div key={key} className="show-branches-single-data"><span className="show-branches-single-data-span">Telefono: </span>{e.area_code}{e.number}</div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="show-branches-single-data-map">

                                    {
                                        e.branch_email.map((e, key) => {
                                            return (
                                                <div key={key} className="show-branches-single-data"><span className="show-branches-single-data-span">Email: </span>{e.email}</div>
                                            )
                                        })
                                    }
                                </div>

                            </div>

                        </div>
                    )

                })
            }

        </div>
    )
}

export default ShowBranches;