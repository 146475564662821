import React from "react";
import "./Intro.scss"
import art from '../../../resources/images/n1.webp'

function Intro() {
    return (
        <div className="intro-container" id="intro">

            <div className="intro-text-container">

                <div className="intro-title">Llena de vida tu jardín con nosotros.</div>
                <div className="intro-text">En Vivero Santa Isabel, te asesoramos para encontrar las plantas ideales y crear el jardín que siempre quisiste tener.</div>

                {/* <button className="intro-btn">Contactanos</button> */}

                <div className="intro-data-container">

                    <div className="intro-data-single">
                        <div className="intro-data-number">90%</div>
                        <div className="intro-data-text">De clientes satisfechos</div>
                    </div>

                    <div className="intro-data-divisor"></div>

                    <div className="intro-data-single">
                        <div className="intro-data-number">87</div>
                        <div className="intro-data-text">Años de experiencia</div>
                    </div>

                </div>

            </div>

            <div className="intro-art-container">
                <img className="intro-art" src={art} alt="Cargando..."></img>
            </div>
        </div>
    )
}

export default Intro;