import React, { useState, useEffect } from "react";
import "./EditBranch.scss"
import { ValidateBranch } from "../../../validations/ValidateBranch";
import axios from "axios";


function EditBranch({ branchId, closeModal }) {

    const [input, setInput] = useState({
        province: "",
        city: "",
        address: "",
        zipCode: "",
        iFrameUrl: ""
    });

    const [errors, setErrors] = useState({});

    const getBranch = async () => {
        try {
            const response = await axios.get(`https://www.viverosantaisabel.com/api/branch/${branchId}`);
            const data = response.data.data;
            
            setInput({
                province: data.province,
                city: data.city,
                address: data.address,
                zipCode: data.zip_code,
                iFrameUrl: data.iframe_url
            });
        } catch (err) {
        }
    };

    useEffect(() => {
        getBranch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
        setErrors(ValidateBranch({ ...input, [e.target.name]: e.target.value }));
    };

    function goBack() {
        closeModal()
    }

    const editBranch = async (e) => {

        const id = parseInt(branchId)
        e.preventDefault();
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            await axios.post("https://www.viverosantaisabel.com/api/branch?_method=PUT", {

                branchId: id,
                province: input.province,
                city: input.city,
                address: input.address,
                zipCode: input.zipCode,
                iFrameUrl: input.iFrameUrl

            },

                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'content-type': 'application/json'
                    }
                });

            closeModal()
        } catch (err) {
        }
    };

    return (
        <div className="edit-branch-container">

            <button className="edit-branch-go-back" onClick={goBack}>Volver</button>

            <div className="edit-branch-title">Editá la sucursal</div>

            <form className="edit-branch-form" onSubmit={(e) => editBranch(e)}>

                <div className="edit-branch-row-container">
                    <div className="edit-branch-input-container">
                        <label className="edit-branch-input-label">Provincia</label>
                        <input
                            className="edit-branch-input"
                            placeholder="Ciudad de la sucursal..."
                            name="province"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                            value={input.province}
                        ></input>

                        {errors.province && (
                            <div className="edit-branch-error">{errors.province}</div>
                        )}
                    </div>

                    <div className="edit-branch-input-container">
                        <label className="edit-branch-input-label">Ciudad</label>
                        <input
                            className="edit-branch-input"
                            placeholder="Ciudad de la sucursal..."
                            name="city"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                            value={input.city}
                        ></input>

                        {errors.city && (
                            <div className="edit-branch-error">{errors.city}</div>
                        )}
                    </div>
                </div>

                <div className="edit-branch-row-container">
                    <div className="edit-branch-input-container">
                        <label className="edit-branch-input-label">Dirección</label>
                        <input
                            className="edit-branch-input"
                            placeholder="Dirección de la sucursal..."
                            name="address"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                            value={input.address}
                        ></input>

                        {errors.address && (
                            <div className="edit-branch-error">{errors.address}</div>
                        )}
                    </div>

                    <div className="edit-branch-input-container">
                        <label className="edit-branch-input-label">Codigo postal</label>
                        <input
                            className="edit-branch-input"
                            placeholder="Codigo postal de la sucursal..."
                            name="zipCode"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                            value={input.zipCode}
                        ></input>

                        {errors.zipCode && (
                            <div className="edit-branch-error">{errors.zipCode}</div>
                        )}
                    </div>

                </div>

                <div className="edit-branch-row-container">
                    <div className="edit-branch-input-container-large">
                        <label className="edit-branch-input-label">Enlace de la ubicación en Google Maps</label>
                        <input
                            className="edit-branch-input"
                            placeholder="Enlace de la ubicación en Google Maps..."
                            name="iFrameUrl"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                            value={input.iFrameUrl}
                        ></input>

                        
                    </div>
                </div>

                <button className={
                    input.province === "" ||
                        input.city === "" ||
                        input.address === "" ||
                        input.zipCode === "" ||
                        errors.province ||
                        errors.city ||
                        errors.address ||
                        errors.zipCode ? "edit-branch-submit-btn-disabled" : "edit-branch-submit-btn"} type="submit">Editar sucursal</button>

            </form>

        </div>
    )
}

export default EditBranch;