import React from "react";
import "./Services.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faSeedling, faTruck } from '@fortawesome/free-solid-svg-icons'

function Services() {
    return (
        <div className="services-container">

            <div className="services-text-container">
                <div className="services-title">Acompañándote desde siempre</div>

                <div className="services-sub-title">Le damos vida a tu jardín desde 1936 y estamos orgullosos del camino que hemos recorrido junto a vos. <br></br> Hoy Vivero Santa Isabel celebra:</div>

                <div className="services-data-container">

                    <div className="services-single-data-container">
                        <div className="services-data-number">+920</div>
                        <div className="services-data-text">Clientes mayoristas</div>
                    </div>

                    <div className="services-single-data-container">
                        <div className="services-data-number">87</div>
                        <div className="services-data-text">Años de historia</div>
                    </div>

                    <div className="services-single-data-container">
                        <div className="services-data-number">17</div>
                        <div className="services-data-text">Provincias con nuestros cultivos</div>
                    </div>

                    <div className="services-single-data-container">
                        <div className="services-data-number">4.5 / 5</div>
                        <div className="services-data-text">Puntuación de clientes en Google</div>
                    </div>

                </div>

            </div>

            <div className="services-row">

                <div className="services-single-container">
                    <FontAwesomeIcon className="services-icon" icon={faComments}></FontAwesomeIcon>
                    <div className="services-single-title">Asesoramiento</div>
                    <div className="services-single-text">Contactanos para recibir el mejor asesoramiento y despejar tus dudas sobre nuestras especies.</div>
                </div>

                <div className="services-single-container">
                    <FontAwesomeIcon className="services-icon" icon={faSeedling}></FontAwesomeIcon>
                    <div className="services-single-title">Venta</div>
                    <div className="services-single-text">Te ofrecemos el mejor y más diverso catálogo de especies para que des vida a tus ambientes.</div>
                </div>

                <div className="services-single-container">
                    <FontAwesomeIcon className="services-icon" icon={faTruck}></FontAwesomeIcon>
                    <div className="services-single-title">Envío</div>
                    <div className="services-single-text">No te preocupes por el envío, nos encargamos nosotros, llegamos a cada rincón del país.</div>
                </div>
            </div>
        </div>
    )
}

export default Services;