import React from "react";
import "./Story.scss"
import Nav from "../Nav/Nav";
import Footer from '../Footer/Footer'
import slide1 from '../../resources/images/historia2.webp'
import slide2 from '../../resources/images/historia1.webp'
import slide3 from '../../resources/images/historia3.webp'
import slide4 from '../../resources/images/historia5.webp'
import slide5 from '../../resources/images/historia6.webp'

function Story() {

    return (
        <div className="story-general-container">
            <Nav></Nav>

            <div className="story-container">

                <div className="story-slide-container">

                    <div className="story-slide-background-image-container">
                        <img className="story-slide-background-image" src={slide1} alt="Cargando.."></img>
                    </div>

                    <div className="story-slide-text-container">
                        <div className="story-slide-title">Nuestra Historia</div>
                        <div className="story-slide-text">Recorre con nosotros la historia de Vivero Santa Isabel, conoce nuestros inicios y el camino que hemos recorrido junto a vos por más de 87 años.</div>
                    </div>

                </div>

                <div className="story-slide-alt-container">

                    <img className="story-slide-alt-image" src={slide3} alt="Cargando.."></img>

                    <div className="story-slide-alt-text-container">
                        <div className="story-slide-alt-title">Érase una vez en <span className="story-slide-alt-title-color">1933</span>...</div>
                        <div className="story-slide-alt-text">
                            Nuestros orígenes se remontan al año 1933, cuando Don Jaime Vicens, con fuerte compromiso y dedicación, funda Vivero Santa Isabel para convertirlo en un próspero proveedor de cultivos y especies de todo tipo.
                          
                        </div>

                        <div className="story-slide-alt-text">
                        Con los años, se unieron a Santa Isabel los 3 hijos del fundador Don Jaime: Rafael Gerardo, Jaime y Bartolomé, quienes, como su padre antes de ellos, transmitieron su pasión a sus propios hijos, Jaime José, Daniel Jaime y Rubén Jaime. Hoy en día, estos herederos, junto con Américo Maseda, son los responsables de liderar con trabajo y pasión la el vivero que Don Jaime alguna vez fundo, valorando siempre los sabios consejos de sus predecesores.
                        </div>
                    </div>

                </div>

                <div className="story-slide-alt2-container">


                    <div className="story-slide-alt2-text-container">
                        <div className="story-slide-alt2-title">La <span className="story-slide-alt2-title-color">Familia</span> crece...</div>
                        <div className="story-slide-alt2-text">
                            Con miras a un futuro prometedor, se unen a la empresa los bisnietos de Don Jaime: Valeria y Romina, hijas de Rubén; Maximiliano, hijo de Américo Maseda;
                            y Andrés, hijo de Jaime. Estas cuatro generaciones han aportado a lo largo del tiempo una amplia experiencia y un reconocido prestigio a nivel nacional.
                        </div>

                        <div className="story-slide-alt2-text">
                        Estas cuatro generaciones y nuestro equipo de trabajo profesional, que supera los 50 empleados, han aportado a lo largo del tiempo una amplia experiencia y un reconocido prestigio a nivel nacional.
                        </div>

                    </div>

                    <img className="story-slide-alt2-image" src={slide2} alt="Cargando.."></img>


                </div>

                <div className="story-slide2-container">

                    <div className="story-slide2-background-image-container">
                        <img className="story-slide2-background-image" src={slide4} alt="Cargando.."></img>
                    </div>

                    <div className="story-slide2-text-container">
                        <div className="story-slide2-title">De Santa Isabel a la Argentina...</div>
                        <div className="story-slide2-text">Con gran orgullo, En el año 2006, Santa Isabel tuvo el honor de inaugurar su sucursal en Santo Tomé, provincia de Santa Fe. Donde continuo proveyendo los mejores cultivos y especies.</div>
                        <div className="story-slide2-text">En el 2009, se abrió un Garden en San Pedro, convirtiéndose en el primero de la zona en ofrecer una amplia variedad de insumos para la jardinería.</div>
                        <div className="story-slide2-text">Y en el año 2013 apostamos al crecimiento y abrimos en la localidad de Venado Tuerto otra sucursal donde hasta día de hoy ofrecemos cultivos y especies de todo tipo.</div>

                    </div>

                </div>

                <div className="story-slide-alt-container">

                    <img className="story-slide-alt-image" src={slide5} alt="Cargando.."></img>

                    <div className="story-slide-alt-text-container">
                        <div className="story-slide-alt-title">Hasta <span className="story-slide-alt-title-color">hoy</span>...</div>
                        <div className="story-slide-alt-text">
                        Hoy, a más de 87 años de nuestra fundación, celebramos todo lo que hemos construido, junto a ustedes, Vivero Santa Isabel ha logrado dar vida a los ambientes de personas alrededor de toda la República Argentina                        </div>

                        <div className="story-slide-alt-text">
                        Hoy y siempre, le agradecemos a usted por elegirnos siempre para llenar de belleza natural sus espacios, Vivero Santa Isabel seguirá trabajando con pasión y dedicación para continuar alegrando jardines en toda la Argentina.                        </div>

                        <div className="story-slide-alt-text">
                        ¡Gracias por visitar la sección de historia! ¿Te gustaría volver a la página principal?
                        </div>

                        <a className="story-return" href="/">Volver a la página principal</a>

                    </div>

                </div>

            </div>

            <Footer></Footer>
        </div>
    )
}

export default Story;