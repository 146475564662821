import { Routes, Route, useLocation } from "react-router-dom";
import './App.css';

import Home from "./components/Home/Home";

import Products from "./components/Products/Products";
import ProductCategory from "./components/Products/ProductCategory/ProductCategory";

import Posts from "./components/Posts/Posts";
import Post from "./components/Posts/Post/Post";

import Gallery from "./components/Gallery/Gallery";

import Story from "./components/Story/Story";

import Contact from "./components/Contact/Contact";

import Login from "./components/Login/Login";
import Board from "./components/Board/Board";

function App() {
  const location = useLocation();
  return (
    <>
      <Routes location={location} key={location.pathname}>

        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/board" element={<Board/>}/>

        <Route exact path="/" element={<Home/>}/>

        <Route exact path="/productos" element={<Products/>}/>
        <Route path="/productos/:id" element={<ProductCategory/>}/>

        <Route exact path="/galeria" element={<Gallery/>}/>

        <Route exact path="/noticias" element={<Posts/>}/>
        <Route path="/noticias/:id" element={<Post/>}/>

        <Route exact path="/historia" element={<Story/>}/>

        <Route exact path="/contacto" element={<Contact/>}/>
        
      </Routes>
    </>
  );
}

export default App;
