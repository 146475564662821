import React, { useState, useEffect } from "react";
import "./Board.scss"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faNewspaper, faUser, faContactBook, faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import CurrentBoard from "./CurrentBoard/CurrentBoard";

function Board() {

    const navigate = useNavigate();

    const [userIsAdmin, setUserIsAdmin] = useState(false);


    async function LogOut() {
        window.localStorage.removeItem("userCredentials");
        window.localStorage.removeItem("isAdmin");
        navigate("/");
    }

    useEffect(() => {
        const ValidateToken = async () => {

            const userCredentials = window.localStorage.getItem("userCredentials");
            const userToken = JSON.parse(userCredentials);
            if (!userToken) {
                LogOut();
                window.alert("Sesion expirada, ingresa tus datos otra vez.")
                return
            }
            try {
                const tokenData = JSON.parse(atob(userCredentials.split(".")[1]));
                const expirationTime = tokenData.exp * 1000; // convert from seconds to milliseconds
                if (Date.now() > expirationTime) {
                    LogOut();
                    window.alert("Sesion expirada, ingresa tus datos otra vez.")
                }
            } catch (error) {
                console.log("catch")
            }

            const isAdmin = window.localStorage.getItem("isAdmin");
            if (isAdmin === "true") {
                setUserIsAdmin(true)
            } else {
                setUserIsAdmin(false)
            }
        };
        ValidateToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [current, setCurrent] = useState('posts');

    const [currentForm, setCurrentForm] = useState('');

    function ChangeCurrent(e) {
        setCurrentForm('');
        setCurrent(e.target.id);
    }

    function ChangeCurrentForm(e) {
        setCurrentForm(e.target.id);
    }

    function GoBack() {
        setCurrentForm('');
    }


    return (
        <div className="board-general-container">
            <div className="board-menu">

                <div onClick={(e) => LogOut(e)} className="board-menu-single-log-out">
                    <FontAwesomeIcon className="board-menu-icon-log-out" icon={faDoorOpen}></FontAwesomeIcon>
                    <div className="board-single-title-log-out">Cerrar sesión</div>
                </div>

                {
                    userIsAdmin === true && (
                        <div onClick={(e) => ChangeCurrent(e)} id="products" className="board-menu-single">
                            <FontAwesomeIcon className="board-menu-icon" icon={faBoxesStacked}></FontAwesomeIcon>
                            <div className="board-single-title">Productos</div>
                        </div>
                    )
                }

                {
                    userIsAdmin === true && (
                        <div onClick={(e) => ChangeCurrent(e)} id="contact" className="board-menu-single">
                            <FontAwesomeIcon className="board-menu-icon" icon={faContactBook}></FontAwesomeIcon>
                            <div className="board-single-title">Información de contacto</div>
                        </div>
                    )
                }

                {
                    userIsAdmin === true && (
                        <div onClick={(e) => ChangeCurrent(e)} id="users" className="board-menu-single">
                            <FontAwesomeIcon className="board-menu-icon" icon={faUser}></FontAwesomeIcon>
                            <div className="board-single-title">Administrar Usuarios</div>
                        </div>
                    )
                }

                <div onClick={(e) => ChangeCurrent(e)} id="posts" className="board-menu-single">
                    <FontAwesomeIcon className="board-menu-icon" icon={faNewspaper}></FontAwesomeIcon>
                    <div className="board-single-title">Noticias</div>
                </div>

            </div>

            <div className="board-current">
                <CurrentBoard
                    current={current}
                    currentForm={currentForm}
                    ChangeCurrentForm={ChangeCurrentForm}
                    GoBack={GoBack}
                ></CurrentBoard>
            </div>
        </div>
    )
}

export default Board;