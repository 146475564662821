import React, { useState } from "react";
import axios from "axios";
import "./AddPost.scss"
import { ValidatePost } from "../../../validations/ValidatePost";
import spinner from "../../../resources/images/gallery/spinner.gif"


function AddPost({ GoBack }) {
    // Spinner state
    const [isLoading, setIsLoading] = useState(false);

    // useState for input content
    const [input, setInput] = useState({
        title: "",
        text: "",
        videoUrl: "",
        buttonError: false
    });

    // useState for input errors display
    const [errors, setErrors] = useState({});

    // function for input value change and validation
    const handleChange = (e) => {
        setInput({
            ...input,
            buttonError: false,
            [e.target.name]: e.target.value,
        });
        setErrors(ValidatePost({ ...input, [e.target.name]: e.target.value }));
    };


    // useState for file name display
    const [fileName, setFileName] = useState('');

    // useState for file name display
    const [fileError, setFileError] = useState(false);

    // function for file name display
    const handleFileInputChange = (event) => {
        setFileError(false)
        const file = event.target.files[0];
        // File size validation: check if the file size is greater than 5MB (5000000 bytes)
        if (file.size > 1000000) {
            // Display an error message or take appropriate action
            setFileError(true)
            event.target.value = "";
            return;
        }
        setFileName(file);
    };

    // useState for video checkbox
    const [videoCheck, setVideoCheck] = useState(false);

    // function for video checkbox
    const handleVideoCheckboxChange = () => {
        setVideoCheck(!videoCheck);
        setInput({
            ...input,
            videoUrl: "",
        })
    };

    // useState for gallery checkbox
    const [galleryCheck, setGalleryCheck] = useState(false);

    // function for gallery checkbox
    const handleGalleryCheckboxChange = () => {
        setGalleryCheck(!galleryCheck);
        setImages([])
    };


    // useStates for images gallery
    const [images, setImages] = useState([]);
    const [maxImages] = useState(5);
    const [galleryError, setGalleryError] = useState(false);


    // function for images gallery change
    const handleImagesInputChange = (event, index) => {
        setGalleryError(false)
        const image = event.target.files[0];
        if (image.size > 2000000) {
            // Display an error message or take appropriate action
            setGalleryError(true)
            event.target.value = "";
            return;
        } else {
            const newImages = [...images];
            newImages[index] = image;
            setImages(newImages);
        }

    };


    // function for images gallery add
    const addImageInput = (e) => {
        e.preventDefault()
        if (images.length < maxImages) {
            const newImages = [...images, null];
            setImages(newImages);
        }
    };

    // function for images gallery remove
    const removeImageInput = (e, index) => {
        e.preventDefault()
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };

    // useState for gallery checkbox
    const [featuredCheck, setFeaturedCheck] = useState(false);

    // function for featured checkbox
    const handleFeaturedCheckboxChange = () => {
        setFeaturedCheck(!featuredCheck);
    };


    // function for form submit
    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        const formData = new FormData();
        console.log(input)
        if (input.title !== "") formData.append("title", input.title);
        if (input.text !== "") formData.append("text", input.text);
        if (input.thumbnail !== "") {
            console.log(fileName)
            formData.append("thumbnail", fileName);
        }
        if (input.videoUrl !== "") formData.append("videoUrl", input.videoUrl);
        formData.append("featured", featuredCheck);
        if (galleryCheck) {
            console.log(images)
            images.forEach((image) => {
                formData.append(`images`, image);
            });
        }

        try {

            const response = await axios.post('https://www.viverosantaisabel.com/api/post',
                formData,

                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }

            );

            if (response.data.status === 201) {
                GoBack()
            }
        } catch (error) {
            console.log(error)
            setInput({
                ...input,
                buttonError: true
            })
        }
        setIsLoading(false);
    };


    return (
        <div className="add-post-container">

            <div className="add-post-title">Postea una noticia</div>

            {isLoading ? (
                <div className="spinner-container">
                    <img alt="Cargando..." src={spinner} className="spinner"></img>
                </div>
            ) : (
                <form className="add-post-form" onSubmit={(e) => handleSubmit(e)}>

                    <div className="add-post-input-container">
                        <label className="add-post-input-label">Titulo de la noticia</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                            className="add-post-input"
                            placeholder="Titulo de la noticia"
                            name="title"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></input>

                        {errors.title && (
                            <div className="post-form-error">{errors.title}</div>
                        )}
                    </div>

                    <div className="add-post-input-container">
                        <label className="add-post-input-label">Redacta la noticia</label>
                        <label className="add-post-input-label-small">Para generar un salto de linea presiona Mayús + Enter</label>
                        <textarea
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                }
                            }}
                            className="add-post-input-textarea"
                            type="text"
                            placeholder="Redacta la noticia"
                            name="text"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></textarea>

                        {errors.text && (
                            <div className="post-form-error">{errors.text}</div>
                        )}
                    </div>

                    <label className="add-post-input-label">Portada de la noticia</label>

                    <div className="add-post-input-file-container">
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                            className="add-post-input-file"
                            type="file"
                            onChange={handleFileInputChange}
                        ></input>
                        <label className="add-post-file-label">{fileName ? fileName.name : "Arrastra la imagen aca o hace click para seleccionarla."}</label>
                        {fileError && (
                            <div className="post-form-error">Tamaño maximo de imagen: 1MB</div>
                        )}

                        {!fileError && fileName === "" && (
                            <div className="post-form-error">Selecciona una imagen de portada</div>
                        )}

                    </div>



                    <div className="add-post-input-video-container">

                        <div className="add-post-input-checkbox-container">
                            <label className="add-post-input-label">¿Incluir un video?</label>
                            <input
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                                className="add-post-input-checkbox"
                                onChange={handleVideoCheckboxChange}
                                type="checkbox"
                            >
                            </input>
                        </div>

                        {
                            videoCheck && (
                                <div className="add-post-input-container">
                                    <label className="add-post-input-label">Enlace del video</label>
                                    <input
                                        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                                        className="add-post-input"
                                        placeholder="Enlace a el video"
                                        name="videoUrl"
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                    ></input>

                                    {errors.videoUrl && (
                                        <div className="post-form-error">{errors.videoUrl}</div>
                                    )}
                                </div>

                            )
                        }
                    </div>

                    <div className="add-post-input-video-container">

                        <div className="add-post-input-checkbox-container">
                            <label className="add-post-input-label">¿Incluir imagenes?</label>
                            <input
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                                className="add-post-input-checkbox"
                                onChange={handleGalleryCheckboxChange}
                                type="checkbox"
                            >
                            </input>
                        </div>

                        {
                            galleryCheck && (
                                <div className="add-post-input-container">

                                    {images.length < maxImages && (
                                        <button className="add-post-gallery-add" onClick={(e) => addImageInput(e)}>Añadir imagen</button>
                                    )}

                                    {images.map((image, index) => (
                                        <div className="add-post-gallery-single-container" key={index}>
                                            <input
                                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                                className="add-post-gallery-input"
                                                type="file"
                                                onChange={(event) => handleImagesInputChange(event, index)}
                                            />

                                            <button className="add-post-gallery-remove" onClick={(e) => removeImageInput(e, index)}>Borrar</button>


                                        </div>
                                    ))}
                                    {galleryError && (
                                        <div className="post-form-error">La imagen que seleccionaste excede el tamaño maximo: 1MB</div>
                                    )}
                                </div>

                            )
                        }
                    </div>

                    <div className="add-post-input-video-container">

                        <div className="add-post-input-checkbox-container">
                            <label className="add-post-input-label">¿Destacar la noticia?</label>
                            <input
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                className="add-post-input-checkbox"
                                onChange={handleFeaturedCheckboxChange}
                                type="checkbox"
                            >
                            </input>
                        </div>
                    </div>

                    <button
                        className={
                            input.title === "" ||
                                input.text === "" ||
                                errors.title ||
                                errors.text ||
                                (videoCheck && errors.videoUrl) ||
                                fileName === "" ||
                                fileError ||
                                galleryError ? "add-post-submit-btn-disabled" : "add-post-submit-btn"
                        }

                        type="submit">{input.buttonError ? "Error en los datos" : "Postear"}
                    </button>

                </form>
            )}


        </div>
    )
}

export default AddPost;