import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss"
import LoginModal from "../LoginModal/LoginModal";
import axios from "axios";
import NavSimple from "../NavSimple/NavSimple";
import Footer from "../Footer/Footer";
import { validate } from "../../validations/validations";
import loginImg from '../../resources/images/gallery/ft5.webp'
import loginLogo from '../../resources/images/logo.webp'

function Login() {

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);

    function OpenModal() {
        setOpenModal(true)
    }

    function closeModal() {
        setOpenModal(false)
    }

    const [input, setInput] = useState({
        email: "",
        password: ""
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
        setErrors(validate({ ...input, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://www.viverosantaisabel.com/api/employee/login", input);
            window.localStorage.setItem(
                "userCredentials",
                JSON.stringify(response.data.token)
            );

            window.localStorage.setItem(
                "isAdmin",
                (response.data.isAdmin)
            )

            navigate("/board");
            setInput({ email: "", password: "" });
        } catch (e) {
            if (e.response.data.msg === "Not Acceptable") setErrors({ password: "Datos incorrectos" })
            setInput({
                email: "",
                password: "",
            });
        }
    };

    useEffect(() => {
        const ValidateToken = async () => {

            const userCredentials = window.localStorage.getItem("userCredentials");
            const userToken = JSON.parse(userCredentials);

            if(!userToken) return

            const tokenData = JSON.parse(atob(userCredentials.split(".")[1]));

            if(tokenData) {
                const expirationTime = tokenData.exp * 1000; // convert from seconds to milliseconds
                if (Date.now() < expirationTime) {
                    navigate("/board")
                }
            }
        };
        ValidateToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="login-general-container">
            <NavSimple></NavSimple>

            {
                openModal &&
                <div className="login-modal-container">
                    <LoginModal closeModal={closeModal}></LoginModal>
                </div>
            }

            <div className="login-container">
                <form 
                onSubmit={(e) => handleSubmit(e)}
                className="login-form">

                    <img src={loginLogo} className="login-form-logo" alt="Cargando..."></img>

                    <div className="login-form-input-container">
                        <label className="login-form-label">E-mail</label>
                        <input
                            autoComplete="off"
                            name="email"
                            value={input.email}
                            placeholder="Ingresa tu correo electronico"
                            className="login-form-input"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.email && (
                            <div className="login-form-error">{errors.email}</div>
                        )}
                    </div>

                    <div className="login-form-input-container">
                        <label className="login-form-label">Contraseña</label>
                        <input
                            name="password"
                            value={input.password}
                            type={'password'}
                            placeholder="Ingresa tu correo electronico"
                            className="login-form-input"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.password && (
                            <div className="login-form-error">{errors.password}</div>
                        )}
                    </div>

                    <button type="submit" className={errors.email || input.password.length < 3 ? "login-form-submit-btn-disabled" : "login-form-submit-btn"}>Iniciar Sesion</button>

                    <button href="/" onClick={OpenModal} className="login-form-forgot-password">¿Olvidaste tu contraseña?</button>

                </form>

                <div className="login-img-container">
                    <img className="login-img" src={loginImg} alt="Cargando..."></img>
                </div>
            </div>

            <Footer></Footer>
        </div>
    )
}

export default Login;