import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./ProductCategory.scss"
import Footer from '../../Footer/Footer'
import Nav from "../../Nav/Nav";


function ProductCategory() {
    
    const navigate = useNavigate();
    let params = useParams();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const response = await axios.get(
                    `https://www.viverosantaisabel.com/api/product/category/${params.id}?page=${currentPage}`
                );

                if(response.data.status === 404) navigate(`/productos`);

                const data = response.data.data;
                setProducts(data);
                setTotalPages(response.data.totalPages);
            } catch (err) {
                navigate(`/productos`);
            }
        };
        getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, currentPage]);

    const handlePrevPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageButtons = () => {
        const maxButtons = 5;
        const halfButtons = Math.floor(maxButtons / 2);
        const startPage = Math.max(currentPage - halfButtons, 1);
        const endPage = Math.min(startPage + maxButtons - 1, totalPages);

        const buttons = [];
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    className={`product-pagination-btn-number ${i === currentPage ? "active" : ""}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }

        return buttons;
    };

    return (
        <div className="product-category-general-container">
            <Nav></Nav>

            {
                products[0] && (
                    <div className="products-title-container">
                        <div className="products-title">Santa Isabel <span className="products-title-color">{products[0].product_categories.name}</span></div>
                    </div>
                )
            }

            <div className="product-category-container">

                <div className="product-category-list-container">
                    {
                        products && products.map((e) => {
                            return (
                                <div key={e.product_id} className="product-category-list-single">
                                    {e.image && (
                                        <img
                                            className="product-category-list-img"
                                            src={
                                                e.image
                                                    ? URL.createObjectURL(
                                                        new Blob([new Uint8Array(e.image.data)], {
                                                            type: "image/png",
                                                        })
                                                    )
                                                    : ""
                                            } alt="">
                                        </img>
                                    )}
                                    <div className="product-category-list-single-text">{e.description}</div>
                                </div>

                            )


                        })
                    }
                </div>

                <div className="product-pagination-container">
                    <button
                        className="product-pagination-btn"
                        disabled={currentPage === 1}
                        onClick={handlePrevPage}
                    >
                        Anterior
                    </button>

                    <div className="product-pagination-btn-number-container">
                        {renderPageButtons()}
                    </div>

                    <button
                        className="product-pagination-btn"
                        disabled={currentPage === totalPages}
                        onClick={handleNextPage}
                    >
                        Siguiente
                    </button>
                </div>

            </div>

            <Footer></Footer>
        </div>
    )
}

export default ProductCategory;