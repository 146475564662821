import React from 'react';

const YoutubeEmbed = ({ url }) => {
  // Extract the YouTube video ID from the URL
  const videoId = url.match(/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]{11})/);

  if (videoId) {
    // If a valid video ID is found, render the YouTube embed video player
    return (
      <div style={{width: "100%", height: "60vh"}}>
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId[1]}`}
          title="YouTube Video Player"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  } else {
    // If no valid video ID is found, render an error message
    return <div>Error: Invalid YouTube URL</div>;
  }
};

export default YoutubeEmbed;