import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import "./HomeLanding.scss";
import { Link } from "react-scroll";
import logo from '../../../resources/images/onlylogotransparent.webp';
import image1 from '../../../resources/images/home/hero/carrusel1.webp';
import image2 from '../../../resources/images/home/hero/carrusel2.webp';
import image3 from '../../../resources/images/home/hero/carrusel3.webp';
import image4 from '../../../resources/images/home/hero/carrusel4.webp';

function HomeLanding() {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });
    const [currentImage, setCurrentImage] = useState(0);
    const images = [image1, image2, image3, image4];
    const [imageLoaded, setImageLoaded] = useState(false); // Track if all images are loaded

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 9)
            });
        }
    }, []);

    useEffect(() => {
        // Pre-load all images
        const imagePromises = images.map((imageSrc) => {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = imageSrc;
                image.onload = resolve;
                image.onerror = reject;
            });
        });

        Promise.all(imagePromises)
            .then(() => {
                // All images are loaded
                setImageLoaded(true);
            })
            .catch((error) => {
                console.error("Failed to load images:", error);
            });

        const timer = setInterval(() => {
            setCurrentImage((currentImage) => (currentImage + 1) % images.length);
        }, 5000);
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images.length]);

    const bgStyle = {
        backgroundImage: imageLoaded ? `url(${images[currentImage]})` : "none",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        transition: 'background-image 0.5s ease-out',
    };

    const dotContainerStyle = {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: '20px',
        width: '100%',
        "zIndex": '97'
    };

    const dotStyle = {
        width: '2vh',
        height: '2vh',
        borderRadius: '50%',
        background: '#eee',
        margin: '0 8px',
        cursor: 'pointer',
        transition: 'background-color 0.5s ease-out'
    };

    const activeDotStyle = {
        ...dotStyle,
        background: '#cf302c'
    };

    const handleDotClick = (index) => {
        setCurrentImage(index);
    }

    return (
        <div className="home-landing-container" id="home" style={bgStyle}>
            <div ref={targetRef} className="home-landing-text-container">
                <div className="home-landing-logo-container">
                    <img className="home-landing-logo" src={logo} alt="Cargando..."></img>
                </div>
                <div className="home-landing-title">Vivero Santa Isabel S.A</div>
                <div className="home-landing-bottom-title">Expertos en llevar la belleza natural a tus espacios.</div>
                <Link className="home-landing-btn"
                    href="*"
                    to="intro"
                    spy={true}
                    smooth={true}
                    offset={dimensions.offsetHeight}
                    duration={1500}>Comenzar
                </Link>
            </div>
            <div style={dotContainerStyle}>
                {images.map((image, index) => (
                    <div key={index}
                        style={index === currentImage ? activeDotStyle : dotStyle}
                        onClick={() => handleDotClick(index)}
                    ></div>
                ))}
            </div>
        </div>
    )
}

export default HomeLanding;
