import React, { useEffect, useState } from "react";
import "./Featured.scss";
import axios from "axios";
import logo from '../../../resources/images/footerlogo.webp'

function Featured() {

    const [featuredPosts, setFeaturedPosts] = useState([]);

    useEffect(() => {
        const getPost = async () => {

            try {
                const response = await axios.get(
                    `https://www.viverosantaisabel.com/api/post/featured`
                );
                const data = response.data.data;
                setFeaturedPosts(data);
            } catch (err) {
            }

        };
        getPost();
    }, []);

    return (
        <div className="featured-general-container">

            <div className="featured-list-title">Noticias destacadas</div>
            <div className="featured-list-text">Encontrá acá las últimas novedades de Vivero Santa Isabel</div>

            <div className="featured-list-container">
                {featuredPosts.length > 0 ? (
                    featuredPosts && featuredPosts.slice(0, 4).map((post) => (
                        <a key={post.post_id} href={`/noticias/${post.post_id}`} className="featured-list-single-container">
                            <img
                                className="featured-list-single-img"
                                key={post.post_id}
                                src={
                                    post.thumbnail
                                        ? URL.createObjectURL(
                                            new Blob([new Uint8Array(post.thumbnail.data)], {
                                                type: "image/png",
                                            })
                                        )
                                        : ""
                                }
                                alt={post.title}
                            ></img>
                            <div className="featured-list-signle-title">{post.title}</div>
                            <div className="featured-list-single-subtitle">
                                {post.text.slice(0, 90)}...
                            </div>
                        </a>
                    ))
                ) : (
                    <div className="post-featured-no-posts">
                        <img className="post-featured-no-posts-img" src={logo} alt="Cargando..."></img>
                        <div className="post-featured-no-posts-text">¡Ahora mismo no tenemos noticias destacadas!</div>
                    </div>
                )}
            </div>


        </div>
    )
}

export default Featured;