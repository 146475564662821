import React, { useState, useEffect } from "react";
import "./AddProduct.scss"
import axios from "axios";
import { ValidateProduct } from "../../../validations/ValidateProduct";


function AddProduct({ GoBack }) {
    
    const [categories, setCategories] = useState([]);

    const [input, setInput] = useState({
        description: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setInput({
            ...input,
            buttonError: false,
            [e.target.name]: e.target.value,
        });
        setErrors(ValidateProduct({ ...input, [e.target.name]: e.target.value }));
    };


    const [productCategory, setProductCategory] = useState(0);
    const [categoryGallery, setCategoryGallery] = useState(false);
    const [productCategoryErr, setProductCategoryErr] = useState('Debe seleccionar una categoria');

    const handleCategoryChange = (event) => {
        setCategoryGallery(false)
        const id = parseInt(event.target.value);
        const selectedCategory = categories.find((e) => e.category_id === id);
        setProductCategory(selectedCategory.category_id);
        setCategoryGallery(selectedCategory.has_gallery)
        if (id === 0) {
          setProductCategoryErr('Debe seleccionar una categoria');
        } else {
          setProductCategoryErr('');
        }
      };
      


    // useState for file name display
    const [fileName, setFileName] = useState('');

    // useState for file name display
    const [fileError, setFileError] = useState(false);

    // function for file name display
    const handleFileInputChange = (event) => {
        setFileError(false)
        const file = event.target.files[0];
        // File size validation: check if the file size is greater than 5MB (5000000 bytes)
        if (file.size > 1000000) {
            // Display an error message or take appropriate action
            setFileError(true)
            event.target.value = "";
            return;
        }
        setFileName(file);
    };


    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await axios.get("https://www.viverosantaisabel.com/api/product/category/");
                const data = response.data.data;
                setCategories(data);
            } catch (err) {
            }
        };
        getCategories();
    }, [])

    const handleSubmit = async (e) => {

        e.preventDefault();

        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        const formData = new FormData();

        formData.append("description", input.description);
        formData.append("categoryId", productCategory);
        formData.append("image", fileName);

        try {

            const response = await axios.post('https://www.viverosantaisabel.com/api/product/',
                formData,

                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }

            );
            if (response.data.status === 201) {
                GoBack()
            }
        } catch (error) {
            setInput({
                ...input,
                buttonError: true
            })
        }
    };

    return (
        <div className="add-product-container">

            <div className="add-product-title">Agrega un producto</div>

            <form className="add-product-form" onSubmit={(e) => handleSubmit(e)}>

                <div className="add-product-input-container">
                    <label className="add-product-input-label">Nombre del producto</label>
                    <input
                        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                        className="add-product-input"
                        placeholder="Nombre del producto..."
                        name="description"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                    ></input>

                    {errors.description && (
                        <div className="product-form-error">{errors.description}</div>
                    )}
                </div>

                <div className="add-product-input-container">
                    <label className="add-product-input-label">Seleccioná la categoria del producto</label>

                    <select className="add-product-select" onChange={handleCategoryChange} value={productCategory}>
                        <option value={0}>Seleccionar categoria</option>
                        {
                            categories && categories.map((e, index) => {
                                return (
                                    <option key={e.id} value={e.category_id}>{e.name}</option>
                                )
                            })
                        }
                    </select>
                    {productCategoryErr &&
                        <div className="product-form-error-alt">{productCategoryErr}</div>
                    }
                </div>

                {
                    categoryGallery && (
                        <div className="add-product-input-file-container">
                            <input
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}

                                className="add-product-input-file"
                                type="file"
                                onChange={handleFileInputChange}
                            ></input>
                            <label className="add-product-file-label">{fileName ? fileName.name : "Arrastra la imagen aca o hace click para seleccionarla."}</label>
                            {fileError && (
                                <div className="product-form-error">Tamaño maximo de imagen: 1MB</div>
                            )}

                            {!fileError && fileName === "" && (
                                <div className="product-form-error">Selecciona una imagen de portada</div>
                            )}

                        </div>
                    )
                }

                <button
                    className=
                    {
                        input.description === "" ||
                            errors.description ||
                            productCategory === 0 ||
                            (categoryGallery && fileName) === "" ||
                            (categoryGallery === 9 && fileError) ? "add-product-submit-btn-disabled" : "add-product-submit-btn"
                    }
                    type="submit">{input.buttonError ? "Error en los datos" : "Crear producto"}
                </button>
            </form>
        </div>
    )
}

export default AddProduct;