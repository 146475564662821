import React from "react";
import "./Footer.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faHome, faPlantWilt, faPhotoVideo, faNewspaper, faScroll } from '@fortawesome/free-solid-svg-icons'

function Footer() {
    return (
        <div className="footer-container">

            <div className="footer-text-container">

                <div className="footer-map-container">

                    <div className="footer-map-title">Mapa del sitio</div>

                    <div className="footer-map-items-container">

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faHome}></FontAwesomeIcon>
                            </span>
                            <a href="/" className="footer-map-item">Home</a>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faPlantWilt}></FontAwesomeIcon>
                            </span>                            
                            <a href="/productos" className="footer-map-item">Productos</a>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faPhotoVideo}></FontAwesomeIcon>
                            </span>                            
                            <a href="/galeria" className="footer-map-item">Galería</a>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faNewspaper}></FontAwesomeIcon>
                            </span>                            
                            <a href="/noticias" className="footer-map-item">Noticias</a>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faScroll}></FontAwesomeIcon>
                            </span>                            
                            <a href="/historia" className="footer-map-item">Historia</a>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faPhone}></FontAwesomeIcon>
                            </span>                            
                            <a href="/contacto" className="footer-map-item">Contacto</a>
                        </div>
                    </div>

                </div>

                <div className="footer-contact-container">
                    <div className="footer-contact-title">Contacto</div>

                    <div className="footer-contact-items-container">
                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faPhone}></FontAwesomeIcon>
                            </span>
                            <div className="footer-contact-number">San Pedro: +54 3329 582241</div>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faPhone}></FontAwesomeIcon>
                            </span>
                            <div className="footer-contact-number">San Pedro: +54 3329 410429</div>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faPhone}></FontAwesomeIcon>
                            </span>
                            <div className="footer-contact-number">Santo Tomé +54 342 4742472</div>
                        </div>

                        <div className="footer-contact-single">
                            <span className="fa-layers">
                                <FontAwesomeIcon className="footer-icon" icon={faPhone}></FontAwesomeIcon>
                            </span>
                            <div className="footer-contact-number">Venado Tuerto +54 3462 316418</div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="footer-media-container">
                <div className="footer-media-title">Encontranos en</div>

                <div className="footer-media-icons-container">
                    <a className="footer-media-a" target="_blank" rel="noreferrer" href="https://www.facebook.com/viverosantaisabelsp"><FontAwesomeIcon className="footer-media-icon" icon={faFacebook}></FontAwesomeIcon>Facebook</a>
                    <a className="footer-media-a" target="_blank" rel="noreferrer" href="https://www.instagram.com/viverosantaisabelsp/"><FontAwesomeIcon className="footer-media-icon" icon={faInstagram}></FontAwesomeIcon>Instagram</a>
                    <a className="footer-media-a" target="_blank" rel="noreferrer" href="https://www.google.com/search?gs_ssp=eJzj4tZP1zcsSUkuLswxN2C0UjWosDRNSjQyNExNtTRJNE4yMrQyqDA0SklLTk1KNTNJMUszN0nxEi7LLEstylcoTswrSVTILE5MSs0BACOMFvE&q=vivero+santa+isabel&rlz=1C1ONGR_esAR1010AR1010&oq=vivero+snaa&aqs=chrome.2.69i57j46i13i175i199i512l2j0i13i512l2j46i13i175i199i512j0i13i512l3j69i64.5631j0j4&sourceid=chrome&ie=UTF-8#lrd=0x95ba211ee94a3b21:0x12dfcebe64d6f74d,1,,,,"><FontAwesomeIcon className="footer-media-icon" icon={faGoogle}></FontAwesomeIcon>Google</a>
                </div>

                <div className="footer-media-news-title">Sobre nosotros</div>

                <div className="footer-media-news-text">En Vivero Santa Isabel somos uno de los productores más grandes de La Argentina, contamos con todo tipo de especies, productos y herramientas para tus proyectos o jardín.</div>

            </div>
        </div>
    )
}

export default Footer;