import React from "react";
import "./Products.scss"
import Nav from "../Nav/Nav";
import Footer from '../Footer/Footer'
import LazyLoad from 'react-lazy-load';

import Arboles from '../../resources/images/Arboles.webp'
import Arbustos from '../../resources/images/Arbustos.webp'
import Coníferas from '../../resources/images/Coniferas.webp'
import Rosales from '../../resources/images/Rosales.webp'
import Frutales from '../../resources/images/Frutales.webp'
import Plantines from '../../resources/images/Plantines.webp'
import Cactus from '../../resources/images/Cactus.webp'
import Sustratos from '../../resources/images/Sustratos.webp'
import Herramientas from '../../resources/images/Herramientas.webp'
import Deco from '../../resources/images/Deco.webp'
import Palmeras from '../../resources/images/example6.webp'

function Products() {

    const productCategories = [
        { id: 1, name: "Árboles", img: Arboles },
        { id: 2, name: "Arbustos", img: Arbustos },
        { id: 3, name: "Coníferas", img: Coníferas },
        { id: 4, name: "Rosales", img: Rosales },
        { id: 5, name: "Frutales", img: Frutales },
        { id: 6, name: "Plantines", img: Plantines },
        { id: 7, name: "Cactus", img: Cactus },
        { id: 8, name: "Tierras & Sustratos", img: Sustratos },
        { id: 9, name: "Herramientas", img: Herramientas },
        { id: 10, name: "Deco & Home", img: Deco },
        { id: 11, name: "Palmeras", img: Palmeras }
    ]

    return (
        <div className="products-container">
            <Nav></Nav>
            <div className="products-title-container">
                <div className="products-title">Santa Isabel <span className="products-title-color">Productos y Categorías</span></div>
            </div>

            <div className="product-all-title">Todas las categorías</div>

            <div className="product-all-container">
                {
                    productCategories && productCategories.map((e) => {
                        return (
                            <LazyLoad>

                            <a className="product-all-a" key={e.id} href={`/productos/${e.id}`}>

                                    <img className="product-all-img" src={e.img} alt="Cargando"></img>

                                <div className="product-all-overlay">{e.name}</div>
                            </a>
                            </LazyLoad>

                        )
                    })
                }
            </div>

            <Footer></Footer>
        </div>
    )
}

export default Products;