import React, { useEffect, useState } from "react";
import "./UserEdit.scss";
import axios from "axios";
import { ValidateUserEdit } from "../../../validations/ValidateUserEdit";

function UserEdit({ userId, closeModal }) {

    // function close modal
    function goBack() {
        closeModal()
    }

    const [branches, setBranches] = useState([]);

    // useState for input content
    const [input, setInput] = useState({
        firstName: "",
        lastName: "",
        province: "",
        city: "",
        zipCode: "",
        email: "",
    });

    // useState for input errors display
    const [errors, setErrors] = useState({});

    // function for input value change and validation
    const handleChange = (e) => {
        setInput({
            ...input,
            buttonError: false,
            [e.target.name]: e.target.value,
        });
        setErrors(ValidateUserEdit({ ...input, [e.target.name]: e.target.value }));
    };

    // function to get user and set inputs
    const getUser = async () => {
        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {
            const response = await axios.get(`https://www.viverosantaisabel.com/api/employee/${userId}`, {
                headers: { Authorization: `Bearer ${userToken}` }
            });
            const data = response.data.data;
            setInput({
                firstName: data.first_name,
                lastName: data.last_name,
                province: data.province,
                city: data.city,
                zipCode: data.zip_code,
                email: data.email,
            });
        } catch (err) {
        }
    };

    // useEffect to get user and set inputs
    useEffect(() => {
        getUser();

        const GetBranches = async () => {
            try {
                const response = await axios.get("https://www.viverosantaisabel.com/api/branch/");
                const data = response.data.data;
                setBranches(data)
            } catch (err) {
            }
        };

        GetBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // User branch select options and errors managment
    const [userBranch, setUserBranch] = useState('');
    const [userBranchErr, setUserBranchErr] = useState('Debe seleccionar una sucursal');

    const handleBranchChange = (event) => {
        setUserBranch(event.target.value);
        if (event.target.value === '') {
            setUserBranchErr('Debe seleccionar una sucursal');
        } else {
            setUserBranchErr('');
        }
    };

    // User status select options and errors managment
    const [userStatus, setUserStatus] = useState(0);
    const [userStatusErr, setUserStatusErr] = useState('Debe seleccionar los permisos del usuario');

    const handleStatushChange = (event) => {
        setUserStatus(event.target.value);
        if (event.target.value === '') {
            setUserStatusErr('Debe seleccionar una sucursal');
        } else {
            setUserStatusErr('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userBranchId = parseInt(userBranch)
        const userStatusId = parseInt(userStatus)

        const userCredentials = window.localStorage.getItem("userCredentials");
        const userToken = JSON.parse(userCredentials);

        try {

            const response = await axios.post('https://www.viverosantaisabel.com/api/employee/profile?_method=PUT', {

                employeeId: userId,
                firstName: input.firstName,
                lastName: input.lastName,
                province: input.province,
                city: input.city,
                zipCode: input.zipCode,
                email: input.email,
                branchId: userBranchId,
                categoryId: userStatusId,
            },
                {
                    headers: { Authorization: `Bearer ${userToken}` }
                }

            );
            if (response.data.status === 201) {
                closeModal()
            }
        } catch (error) {
            
        }
    };

    return (

        <div className="user-edit-container">
            <button className="user-edit-go-back" onClick={goBack}>Volver</button>

            <div className="user-edit-title">Editar usuario</div>

            <form className="user-edit-form" onSubmit={(e) => handleSubmit(e)}>

                <div className="user-edit-form-row">

                    <div className="user-edit-input-container">
                        <label className="user-edit-input-label">Nombre</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="user-edit-input"
                            placeholder="Nombre..."
                            value={input.firstName}
                            name="firstName"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.firstName && (
                            <div className="user-form-error">{errors.firstName}</div>
                        )}
                    </div>

                    <div className="user-edit-input-container">
                        <label className="user-edit-input-label">Apellido</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="user-edit-input"
                            placeholder="Apellido..."
                            value={input.lastName}
                            name="lastName"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.lastName && (
                            <div className="user-form-error">{errors.lastName}</div>
                        )}
                    </div>

                </div>

                <div className="user-edit-form-row">

                    <div className="user-edit-input-container">
                        <label className="user-edit-input-label">Provincia</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="user-edit-input"
                            placeholder="Provincia..."
                            value={input.province}
                            name="province"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.province && (
                            <div className="user-form-error">{errors.province}</div>
                        )}
                    </div>

                    <div className="user-edit-input-container">
                        <label className="user-edit-input-label">Ciudad</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="user-edit-input"
                            placeholder="Ciudad..."
                            value={input.city}
                            name="city"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.city && (
                            <div className="user-form-error">{errors.city}</div>
                        )}
                    </div>

                </div>

                <div className="user-edit-form-row">

                    <div className="user-edit-input-container">
                        <label className="user-edit-input-label">Codigo postal</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="user-edit-input"
                            placeholder="Codigo postal..."
                            value={input.zipCode}
                            name="zipCode"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.zipCode && (
                            <div className="user-form-error-alt">{errors.zipCode}</div>
                        )}
                    </div>

                </div>

                <div className="user-edit-form-row">

                    <div className="user-edit-input-email-container">
                        <label className="user-edit-input-label">Correo electronico</label>
                        <input
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            className="user-edit-input"
                            placeholder="Correo electronico..."
                            value={input.email}
                            name="email"
                            autoComplete="off"
                            onChange={(e) => handleChange(e)}
                        ></input>
                        {errors.email && (
                            <div className="user-form-error-alt">{errors.email}</div>
                        )}
                    </div>
                </div>

                <div className="user-edit-input-branch-container">
                    <label className="user-edit-input-branch-label">Sucursal</label>
                    <select className="user-edit-input-branch" onChange={handleBranchChange} value={userBranch}>
                        <option value="">Seleccionar sucursal</option>
                        {
                            branches && branches.map((branch) => {
                                return (
                                    <option value={branch.branch_id}>{branch.city}</option>
                                )
                            })
                        }

                    </select>
                    {userBranchErr &&
                        <div className="user-form-error-alt">{userBranchErr}</div>
                    }
                </div>

                <div className="user-edit-input-branch-container">
                    <label className="user-edit-input-branch-label">Permisos del usuario</label>
                    <select className="user-edit-input-branch" onChange={handleStatushChange} value={userStatus}>
                        <option value="">Selecciona permisos del usuario</option>
                        <option value={1}>Administrador</option>
                        <option value={2}>Publicador</option>
                    </select>
                    {userStatusErr &&
                        <div className="user-form-error-alt">{userStatusErr}</div>
                    }
                </div>


                <button

                    className={
                        input.firstName === "" ||
                            input.lastName === "" ||
                            input.province === "" ||
                            input.city === "" ||
                            input.zipCode === "" ||
                            input.email === "" ||
                            errors.firstName ||
                            errors.lastname ||
                            errors.province ||
                            errors.city ||
                            errors.zipCode ||
                            errors.email ||
                            userBranchErr !== "" ||
                            userStatusErr !== "" ? "user-edit-submit-btn-disabled" : "user-edit-submit-btn"
                    }

                    type="submit">Editar usuario
                </button>

            </form>

        </div>
    )
}

export default UserEdit;